
// 'light' => 'light',
// 'dark' => 'dark',
// 'blue' => 'blue',
// 'red' => 'red',
// 'green' => 'green',


body.theme-color-light{

    /* settings ------------------------*/

    $background:#f9f9f9;
    $surface:#fff;
    
    $primary:#fff;
    $primaryVariant:#fff;
    
    $secondry:#fff;
    $secondryVariant:#fff;
    $onSecondry:#333;

    $error:#fff;

    $color:#000;

    /* settings / ------------------------*/

    background-color: $background;
    color: $color;
    header.header-wrap{
        background-color: $primary;
    }

    .hentry,
    .container-has-bg{
        background-color: $surface;
    }

    #footer,
    #bigfooter{
        background-color: $secondry;
        color: $onSecondry;
    }
    
}

body.theme-color-dark{

     /* settings ------------------------*/

    $background:#333;
    $surface:#000;
    $color:#fff;

    $primary:#000;
    $primaryVariant:#333;
    $onPrimary:#eee;
    
    $secondry:#444;
    $secondryVariant:#222;
    $onSecondry:#ccc;

    $error:#fff;

    background-color: $background;
    color: $color;
    .header-main{
        background-color: $primary;
    }

    .hentry,
    .container-has-bg,
    .widget .widget-inner,
    .slider-container .slider-inner .slick-slide{
        background-color: $surface;
    }

    #header .header-mini,
    ul.header-nav li ul.sub-menu,
    #onlynav,
    #footer{
        background-color: $primary;
        color: $onPrimary;
        border-color: $primaryVariant;
    }

    a.tag-cloud-link,
    a.post-tag,
    .diver-toc{
        color: $onPrimary;
        background-color: $secondry;
    }

    .diver-tabwidget__tabs--item{
        background-color: $secondry;
        &.is-active{
            background-color: $primary;
            color: $onPrimary;
        }
    }

    .post_footer_author {
        border-color: $primaryVariant;
        &_title{
            background-color: $background;
            color: inherit;
        }
    }
    
    .comment-form{
        background-color: $background;
    }
    input[type=text],
    input[type=password],
    input[type=email],
    input[type=search],
    input[type=url],
    input[type=tel],
    input[type=number],
    input[type=date],
    textarea{
        background-color: $secondryVariant;
        color: #fff;
        &:disabled{
            background-color: $secondry;
            color: $onSecondry;
        }
    }
}

body.theme-color-blue{

    /* settings ------------------------*/

    $background:#eee;
    $surface:#fff;
    
    $primary:#021e48;
    $primaryVariant:#fff;
    $onPrimary:#fff;

    $secondry:#013B9A;
    $secondryVariant:#fff;
    $onSecondry:#fff;

    $error:#fff;

    $color:#000;

    /* settings / ------------------------*/

    background-color: $background;
    color: $color;

    header.header-wrap,
    #onlynav,
    #scrollnav,
    .header-nav li ul.sub-menu,
    #bigfooter{
        background-color: $primary;
        color: $onPrimary;
    }

    #header-fix{
        background-color: rgba($secondry, 0.8);
        color: $onSecondry;
        .header-nav ul.sub-menu{
            background-color: rgba($secondry, 0.8);
            color: $onSecondry;
        }
    }

    #header .header-mini,
    #footer{
        background-color: $secondry;
        color: $onSecondry;
    }


    .diver-tabwidget__tabs--item{
        &.is-active{
            border-color: $secondry;
        }
    }

}

body.theme-color-red{

    
    /* settings ------------------------*/

    $background:#fff7f7;
    $surface:#fff;
    
    $primary:#83173b;
    $primaryVariant:#fff;
    $onPrimary:#fff;

    $secondry:#a10808;
    $secondryVariant:#fff;
    $onSecondry:#fff;

    $error:#fff;

    $color:#000;

    /* settings / ------------------------*/

    background-color: $background;
    color: $color;

    header.header-wrap,
    #onlynav,
    #scrollnav,
    .header-nav li ul.sub-menu,
    #bigfooter{
        background-color: $primary;
        color: $onPrimary;
    }

    #header-fix{
        background-color: rgba($secondry, 0.8);
        color: $onSecondry;
        .header-nav ul.sub-menu{
            background-color: rgba($secondry, 0.8);
            color: $onSecondry;
        }
    }

    #header .header-mini,
    #footer{
        background-color: $secondry;
        color: $onSecondry;
    }


    .diver-tabwidget__tabs--item{
        &.is-active{
            border-color: $secondry;
        }
    }

}


body.theme-color-green{

    /* settings ------------------------*/

    $background:#eee;
    $surface:#fff;
    
    $primary:#0d2803;
    $primaryVariant:#fff;
    $onPrimary:#fff;

    $secondry:#0c4a0c;
    $secondryVariant:#03851f;
    $onSecondry:#fff;

    $error:#fff;

    $color:#000;

    /* settings / ------------------------*/

    background-color: $background;
    color: $color;
    
    header.header-wrap,
    #onlynav,
    #scrollnav,
    .header-nav li ul.sub-menu,
    #bigfooter{
        background-color: $primary;
        color: $onPrimary;
    }

    #header-fix{
        background-color: rgba($secondry, 0.8);
        color: $onSecondry;
        .header-nav ul.sub-menu{
            background-color: rgba($secondry, 0.8);
            color: $onSecondry;
        }
    }

    #header .header-mini,
    #footer{
        background-color: $secondry;
        color: $onSecondry;
    }


    .diver-tabwidget__tabs--item{
        &.is-active{
            border-color: $secondry;
        }
    }
}