@import "../setting";

.sc_heading {
    position: relative;
    color: #fff;
    padding: 0.5em 1em;
    margin: 1.5em 0 1em;
    line-height: 1.5;
    box-shadow: none;
    border-radius: 0;
    border: none;
    background: none;
    font-weight: bold;

    & > p{
        padding: 0;
        margin: 0;
    }

    &.solid {
        border-radius: 3px;
    }

    &.bborder {
        border-style: solid;
        &.a {
            border-width: 3px;
            border-radius: 3px;
        }
        &.tb {
            border-width: 3px 0 3px 0;
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
        }
        &.b {
            border-width: 0 0 3px 0;
            background-color: transparent;
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
        }
        &.l {
            border-width: 0 0 0 8px;
            background-color: #eee;
        }
    }
    &.stech {
        border: dashed 2px #fff;
        border-radius: 3px;
    }
    &.rlborder {
        text-align: center;
        display: flex;
        background: transparent;
        justify-content: space-between;
        align-items: center;
        gap: 1.5em;
        padding: 0;
        &:before,
        &:after {
            content: "";
            min-width: 50px;
            height: 4px;
            border-radius: 5px;
            background-color: black;
            flex: 1;
        }
        .sc_title {
            display: inline-block;
            position: relative;
        }
    }

    &.fukidasi {
        position: relative;
        background-color: #000;
        border-radius: 6px;
        color: #fff;
        &:after,
        .after {
            content: "";
            position: absolute;
            top: 100%;
            left: 1.5em;
            width: 0;
            height: 0;
            border: 0.5em solid transparent;
            border-top-color: inherit;
            z-index: 1;
        }
    }
    &.headtag {
        vertical-align: middle;
        border-radius:50em 0 0 50em;
        display: flex;
        align-items: center;
        &:before {
            content: "";
            display: block;
            flex: none;
            background-color: #fff;
            margin-right: .5em;
            height: .8em;
            width: .8em;
            border-radius: 50%;
        }
    }

    &.count {
        position: relative;
        background: #efefef;
        color: #000;
        padding-left: 3.2em;
        .before {
            position: absolute;
            color: white;
            background: #000;
            font-size: 1.2em;
            width: 2.2em;
            text-align: center;
            left: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &.countrad {
        position: relative;
        background: #eee;
        color: #000;
        border-radius: 5px;
        margin-left: 1.2em;
        padding-left: 2em;
        .before {
            position: absolute;
            color: white;
            background: #000;
            font-size: 1.2em;
            width: 2.2em;
            line-height: 2.2em;
            text-align: center;
            left: -1em;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
        }
    }

    .custom{
        &:before,
        &:after{
            content:none;
        }
    }

    /*----- color -------------------*/

    @each $key, $color in $auxiliary-color {
        &.#{$key} {

            color: map-get($color, 'text');
            background-color: map-get($color, 'bg');

            &.rlborder{
                background-color: transparent;
                color: map-get($color, 'bg');
                &:before,
                &:after{
                    background-color: map-get($color, 'bg');
                }

            }

            &.bborder{
                background-color: transparent;
                border-color: map-get($color, 'bg');
                color: map-get($color, 'bg');
                &.l{
                    background-color: rgba(map-get($color, 'bg'), 0.1);
                }
            }

            &.fukidasi{
                &:after{
                    border-top-color: map-get($color, 'bg');
                }
            }

            &.count,
            &.countrad{
                background-color: rgba(map-get($color, 'bg'), 0.1);
                color: map-get($color, 'bg');
                .before{
                    background-color: map-get($color, 'bg');
                }
            }

        }
    }

}