.post-title-list{
    &__item{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        &:before{
            content: "\f0da";
            opacity: .8;
            margin-right: 0.6em;
            position: relative;
            top: 1px;
            font-family: "FontAwesome";
        }
    }
}

.content .wrap-post-box {
    margin-bottom: 1em;
}

.wrap-post-box {
    display: grid;
    // margin-bottom: 1em;

    // .wpp-list li:nth-child(1):before,
    // .diver_popular_posts li.widget_post_list:nth-child(1):before{

    // }

    // .wpp-list li:before,
    // .diver_popular_posts li.widget_post_list:before

    &.has-gap {
        grid-gap: 6px;
    }

    &.type-list {
        // .post-box{

        //     .post-box-contents{
        //         grid-gap: 15px;
        //     }

        //     &.has-shadow{
        //         .post-box-contents{
        //             padding: 15px;
        //         }
        //     }
        // }
    }

    &.type-grid {
        // .post-box{
        //     &__meta{
        //         padding: 15px;
        //     }
        // }
        .post-box-contents {
            flex-direction: column;
        }

        .post-box-thumbnail {
            width: 100%;
            max-width: none;
        }

        .has-shadow {
            .post-box-thumbnail {
                width: calc(100% + 30px);
                margin: -15px;
                margin-bottom: 0;
            }
        }
    }

    &.is-size-mini {
        font-size: 14px;
        .post-box-contents {
            grid-gap: 10px;
        }

        .post-box {
            &.has-shadow {
                .post-box-contents {
                    padding: 10px;
                }
            }
            &__meta {
                grid-gap: 5px;
            }

            .newlabel {
                font-size: 0.6em;
            }

            .post-title{
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
            
        }

        &.type-grid {
            .has-shadow {
                .post-box-thumbnail {
                    width: calc(100% + 20px);
                    margin: -10px -10px 0 -10px;
                }
            }
        }
        &.type-list {
            .post-box-thumbnail {
                width: 140px;
            }
            .post-substr{
                -webkit-line-clamp: 2;
            }
        }
    }

    &.dpp {
        counter-reset: dpp-ranking;
        .post-box {
            position: relative;
            &:before {
                content: counter(dpp-ranking, decimal);
                counter-increment: dpp-ranking;
                line-height: 20px;
                width: 20px;
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                background-color: #313131;
                color: #fff;
                font-size: 0.8em;
                font-weight: bold;
                border-bottom-right-radius: 5px;
                z-index: 1;
            }
            &:nth-child(1):before {
                background-color: rgb(255, 218, 9);
                width: 25px;
                line-height: 25px;
                font-size: 1em;
            }
            &:nth-child(2):before {
                background-color: #ccc;
                width: 25px;
                line-height: 25px;
                font-size: 1em;
            }
            &:nth-child(3):before {
                background-color: rgba(255, 121, 37, 0.8);
                width: 25px;
                line-height: 25px;
                font-size: 1em;
            }

            // &:before,
            // &:after {
            //     content: "";
            //     position: absolute;
            //     left: 0px;
            //     width: 0px;
            //     height: 0px;
            //     border-color: inherit;
            //     z-index: 1;
            // }
            // &:before {
            //     top: -20px;
            //     border: 15px solid transparent;
            //     border-bottom: 25px solid;
            //     border-bottom-color: inherit;
            // }
            // &::after {
            //     content: counter(dpp-ranking, decimal);
            //     counter-increment: dpp-ranking;
            //     font-size: .8em;
            //     font-weight: bold;
            //     color: #fff;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     top: 3px;
            //     border-top: 10px solid transparent;
            //     border-left: 15px solid;
            //     border-right: 15px solid;
            //     border-bottom: 8px solid;
            //     border-left-color: inherit;
            //     border-right-color: inherit;
            //     border-bottom-color: inherit;
            // }
        }
    }
}

*:not(.wrap-post-box) > .post-box {
    font-size: 0.8em;
    margin-bottom: 1.6em;
    .post-box-thumbnail {
        width: 140px;
    }
    .post-box__meta {
        grid-gap: 5px;
    }
}

.post-box {
    transition: 0.4s;
    @include mq-up(sm) {
        &:hover {
            transform: translate(0, -3px);
        }
    }
    a:hover{
        opacity: 1;
    }

    .post-box-contents {
        display: flex;
        grid-gap: 15px;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        text-decoration: none;
        color: currentColor;
    }

    &.has-shadow {
        box-shadow: $box-shadow;
        .post-box-contents {
            padding: 15px;
        }
    }

    // &.type-list{
    //     &.has-gap{
    //         grid-gap: 10px;
    //     }
    //     .post-box{

    //         .post-box-contents{
    //             grid-gap: 15px;
    //         }

    //         &.has-shadow{
    //             .post-box-contents{
    //                 padding: 15px;
    //             }
    //         }

    //         .post-box-thumbnail{
    //             width: 280px;
    //             max-width: 40%;
    //         }
    //     }

    // }

    // &.is-self{
    //     font-size: .8em;
    //     margin-bottom: 1.6em;
    //     .post-box-thumbnail{
    //         width: 120px;
    //     }
    //     .post-box__meta{
    //         grid-gap: 5px;
    //     }
    // }

    .post-box-thumbnail {
        position: relative;
        width: 280px;
        max-width: 40%;
        &__wrap {
            overflow: hidden;
            position: relative;
            background-color: #ddd;
            &::before {
                content: "";
                display: block;
                height: 0;
            }
            amp-img,
            img {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                font-family: "object-fit:cover;";
                transition: 0.2s ease-in-out;
            }
        }
        & + .post-box__meta{
            padding: 0;
        }
    }

    .post-title {
        font-weight: bold;
        line-height: 1.3;
    }
    .post-box-taxonomy {
        flex-wrap: wrap;
        display: flex;
        gap: 5px;
    }

    .post-cat,
    .post-tag {
        display: inline-block;
        vertical-align: middle;
        background: #eee;
        color: #000;
        padding: 2px 6px;
        font-size: 0.6em;
        border-radius: 2px;
        position: relative;
    }

    .post-cat {
        color: #fff;
        background: #999;
    }

    .post-substr {
        font-size: 0.8em;
        opacity: 0.7;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }

    .post-author {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        margin-left: auto;
        .post-author-thum img {
            border-radius: 50%;
        }
        @include mq-down(md){
            .post-author-name{
                display: none;
            }
        }
    }

    .post-view{
        font-size: .6em;
        letter-spacing: .15em;
        position: absolute;
        bottom:3px;
        background: #fff;
        color: #000;
        opacity: .8;
        z-index: 1;
        right: 3px;
        padding: 2px 4px;
        border-radius: 1px;
        &::before {
            content: "\f06e";
            font-family: 'FontAwesome';
            padding: 0 .4em;
        }
    }

    &__meta {
        display: flex;
        flex: 1;
        grid-gap: 8px;
        flex-direction: column;
        word-wrap: break-word;
        padding: 5px;
        &--footer {
            display: flex;
            align-items: flex-end;
            align-items: center;
            margin-top: auto;
            font-size: 0.8em;
            gap:.8em;
        }
    }

    &:not(.has-shadow) {
        .post-box-contents {
            grid-gap: 10px;
        }
        .post-box__meta {
            padding: 0;
        }
    }

    .newlabel {
        $this: &;
        z-index: 20;
        display: inline-block;
        position: absolute;
        text-align: center;
        font-size: 8px;
        color: #fff;
        background-color: #f00;
        font-weight: bold;
        top: 0;
        left: 0;
        span {
            white-space: nowrap;
            display: block;
        }
        &:before,
        &:after {
            position: absolute;
            content: "";
        }
    
    }
    
}

.notfound_message {
    text-align: center;
    padding: 3em;
    opacity: .6;
}