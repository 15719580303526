/* layout -----------------------------------------*/

.single-article-container {
    position: relative;
    display: flex;
    gap: 15px;
    &__main {
        flex: 1;
    }
    &__side {
        &--inner {
            padding: 10px;
            position: sticky;
            top: 72px;
        }
        @include mq-down(md) {
            display: none;
        }
    }
}

section.single-post-main {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    margin: 2em 0;
    .content {
        background-color: inherit;
    }
}

header.article-header {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
}

footer.article-footer {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    .single_title {
        margin-top: 14px;
        font-weight: bold;
    }
}

/* title -----------------------------------------*/

h1.single-post-title {
    margin: 10px 0;
}

/* taxonomy -----------------------------------------*/

.post-taxonomy {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 6px;
}

.post-category {
    font-size: 0.7em;
    background: #333;
    color: #fff;
    padding: 0.3em 1em;
    border-radius: 2px;
    display: inline-block;
    vertical-align: middle;
    font-weight: bold;
    text-decoration: none;
}

.post-meta {
    & > * {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    a.post-tag {
        font-size: 0.7em;
    }
}

/* post-meta -----------------------------------------*/

.post-meta-bottom {
    font-size: 0.8em;
    display: flex;
    align-items: flex-end;
    grid-gap: 8px;
    &__item {
        &:before {
            font-family: "fontAwesome";
            margin-right: 4px;
        }
        &.post-published:before {
            content: "\f133";
        }
        &.post-modified:before {
            content: "\f021";
        }
        &.post-reading:before {
            content: "\f017";
        }
    }

    .post-author {
        display: flex;
        align-items: center;
        grid-gap: 6px;
        margin-left: auto;
        margin-right: 0;
        .post-author-thum img {
            border-radius: 50%;
        }
    }

    @include mq-down(md){
        font-size: .7em;
        .post-author-name{
            display: none;
        }
    }
}

/* thumbnail -----------------------------------------*/

.single_thumbnail {
    text-align: center;
    position: relative;
    z-index: 0;
    overflow: hidden;
    margin: 0 -28px;
   img {
    width: 100%;
        max-width: 100%;
        height: auto;
        vertical-align: middle;
        object-fit: contain;
        font-family: "object-fit:contain;";
    }
    figcaption {
        background-color: inherit;
        text-align: right;
        padding: 0.2em 1em;
        font-size: 0.8em;
    }
    &.type-youtube{
        padding-top: 56.25%;
        height:0;
        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    @include mq-down(md){
        margin: 0 -14px;
    }
}

/* slider -----------------------------------------*/

.navigation-post {
    $this: &;
    display: flex;
    width: 100%;
    grid-gap: 2px;
    font-size: 0.9em;

    &__info {
        font-size: 0.9em;
        font-weight: bold;
        margin-bottom: 6px;
    }

    &__item {
        flex: 1;
        padding: 14px 16px;
        &.next_post {
            text-align: right;
            a {
                justify-content: flex-end;
                &:after {
                    content: "\f105";
                }
            }
        }
        &.previous_post {
            a {
                justify-content: flex-start;
                &:before {
                    content: "\f104";
                }
            }
        }
    }
    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-gap: 14px;
        width: 100%;
        position: relative;
        &:before,
        &:after {
            font-size: 2em;
            display: flex;
            align-items: center;
            font-family: "FontAwesome";
        }
    }
    &__title {
        position: relative;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-weight: bold;
        font-size: 0.9em;
    }
    &__thumb {
        position: relative;
        width: 100px;
        background-color: #eee;
        &:before {
            content: "";
            padding-top: 61.8%;
            display: block;
        }
        amp-img,
        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    @include mq-down(md) {
        flex-direction: column;
    }
}

/* footer -----------------------------------------*/

.post-author-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    line-height: 1.5;
    &.container-has-bg {
        padding: 1.5em;
    }
    &.has-shadow {
        box-shadow: $box-shadow;
        padding: 1.5em;
    }
    .post-author-box__avator {
        text-align: center;
        img {
            border-radius: 50%;
        }
    }
    .post-author-box__meta {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 0.8em;
        padding: 0.5em;
        .post-author-name {
            font-size: 1.1em;
            line-height: 1;
            letter-spacing: 0;
            font-weight: bold;
        }
        .post-author-description {
            font-size: 0.8em;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 3;
            // overflow: hidden;
        }
        &--footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .diver-sns-btns {
                flex: 1;
                justify-content: flex-start;
            }
        }
    }

    @include mq-down(md) {
        flex-direction: column;
        .post-author-name {
            text-align: center;
        }
        // .post_author{
        //     text-align: center;
        // }
    }
}

// .post_footer_author_title_post {
//     font-weight: bold;
//     border-bottom: 2px dotted #eee;
//     margin: 15px 0 10px;
// }

/* cta -----------------------------------------*/

#cta {
    background: #fff;

    .cta-title {
        font-size: 1.6em;
        text-align: center;
        padding: 20px;
        background: #32454e;
        color: #fff;
        font-weight: bold;
    }

    .cta-inner {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 16px;
        padding: 24px;

        @include mq-up(md) {
            &.is-layout-full {
                flex-direction: column;
                .cta-thumbnail {
                    width: 100%;
                }
            }
            &.is-layout-right {
                flex-direction: row-reverse;
            }
        }

        @include mq-down(md) {
            flex-direction: column;
            .cta-thumbnail {
                width: 100%;
            }
        }
    }

    .cta-content {
        flex: 1;
    }
    .cta-thumbnail {
        text-align: center;
        width: 40%;
        img {
            height: auto;
            object-fit: contain;
            font-family: "object-fit:contain;";
            max-height: 300px;
        }
    }

    .cta_btnarea {
        text-align: center;
        width: 100%;
    }
}

/* sns -----------------------------------------*/

.sticky-share {
    flex-direction: column;
    a.sns {
        flex-direction: column;
        width: 100%;
        gap: 3px;
        .sns_icon {
            width: 20px;
            height: 20px;
        }
        .sns_name {
            font-size: 0.9em;
        }
    }

    &.has-name {
        a.sns {
            padding: 1.1em 1.1em 0.9em;
        }
        &.is-size-small {
            a.sns {
                padding: 0.8em;
            }
        }
    }

    &:not(.has-name) {
        a.sns {
            padding: 0;
            width: 60px;
            height: 58px;
            border-radius: 3px;
            .sns_icon {
                width: 24px;
                height: 24px;
            }
        }
        &.is-size-small {
            a.sns {
                width: 46px;
                height: 44px;
                .sns_icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

//   #share_plz .fb-like.share_sns.fb_iframe_widget {
//       height: 40px;
//   }

//   #share_plz .p-entry__pushButton{
//       width: auto;
//   }

//   #share_plz .p-entry__pushLike{
//       padding:0;
//       height: 78px;
//   }

//   #share_plz .p-entry__push{
//       margin:0;
//       background: #fff;
//   }

/* big share -----------------------------------------*/

.sns-list-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px;
    overflow: hidden;
    background-color: #000;
    color: #fff;
    &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        grid-gap: 15px;
        background-color: transparent;
    }
    &__title {
        font-size: 1.1em;
        font-weight: bold;
        line-height: 1.4;
    }
    &__desc {
        font-size: 0.8em;
        opacity: 0.8;
    }
    &__overlay {
        filter: blur(2px);
        opacity: 0.5;
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        background-position: center;
        background-size: cover;
    }
}

/* appeal -----------------------------------------*/

.appeal_box {
    background: #333;
    color: #fff;
    .appeal_title {
        padding: 10px;
        background: #333;
        color: #fff;
        font-weight: bold;
    }
    .appeal_img {
        text-align: center;
        max-width: 100%;
        img{
            width: 100%;
        }
    }
    .appeal_desc {
        // font-size: .9em;
        // margin-bottom: 1em;
    }
    .button {
        text-align: center;
        margin-top: 15px;
        a {
            background: #f44336;
        }
    }

    &.widget .widget-inner {
        background: transparent;
        padding: 15px 20px 20px;
    }
}

/* password -----------------------------------------*/
.content-password {
    padding: 30px;
    margin: 2em 0;
    box-shadow: $box-shadow;
    background: #fff;
    color: #000;
    &__title {
        font-size: 1.1em;
        color: #ff9800;
        font-weight: bold;
    }
    &__text {
        font-size: 0.9em;
        padding: 10px 0;
    }
    &__form {
        position: relative;
        display: flex;
        grid-gap: 1em;
        margin-top: 1em;
        input[type="password"] {
            flex: 1;
            padding: 12px 18px;
            background-color: #fff;
            width: 100%;
        }
        input[type="submit"] {
            font-size: 0.8em;
            background-color: #000;
            color: #fff;
        }
        @include mq-down(md) {
            flex-direction: column;
        }
    }
}

/* widget -----------------------------------------*/
.single-related-area{
    .single_title{
        margin-bottom: 1em;
    }
}