.is-scroll-disabled {
    overflow: hidden;
}

// @media screen and (max-width: 768px) {

//     .col2_sp {
//         width: 50%;
//         float: left;
//     }
//     .col3_sp {
//         width: 33.3%;
//         float: left;
//     }
// }

// menu ========================================

ul.menu {
    &.horizontal-menu {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }
    font-weight: bold;

    font-size: 0.9em;
    // .menu_title{
    //     font-weight: bold;
    // }
    .menu_desc {
        display: none;
        font-size: 0.8em;
        opacity: 0.5;
        font-weight: normal;
    }
    li.menu-item {
        position: relative;

        a {
            position: relative;
            display: inline-block;
            padding: 0.8em 1.2em;
            height: 100%;
            white-space: nowrap;
            text-decoration: none;
        }
        ul.sub-menu {
            border-color: #eee;
            font-size: 1.3rem;
            margin-left: 1em;
            // position: absolute;
            // min-width: 100%;
            // z-index: 9;
            // margin:0;
            // top:100%;
            // text-align: left;
            // visibility: hidden;
            // opacity: 0;
            // background-color: inherit;
            // box-shadow: 0 0 8px rgb(0 0 0 / 20%);
            // font-size: 1.3rem;
            li.menu-item {
                &:not(:last-child) {
                    // border-bottom: 1px solid #eee;
                    border-color: inherit;
                }
                a {
                    width: 100%;
                    padding: .4em;
                }
                .menu_desc {
                    display: none !important;
                }
                ul.sub-menu {
                    li.menu-item {
                        // border: none;
                    }
                }
            }
        }
    }
}

// tagcloud ========================================

// #single-main .tag .tag-wrap {
//     margin-left: 120px;
// }

.tagcloud,
.wp-block-tag-cloud {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 5px;
}

a.tag-cloud-link,
a.post-tag {
    font-size: 0.8em;
    display: inline-block;
    padding: 0.3em 0.5em;
    margin: 0;
    border-radius: 3px;
    vertical-align: middle;
    background: #efefef;
    color: #666;
    text-decoration: none;
    &:before {
        content: "\f02b";
        margin-right: 5px;
        color: #7e9da7;
        font-family: fontAwesome;
    }
}

/* Breadcrumb -----------------------------------------*/


#breadcrumb {
    font-size: 0.8em;
    padding: 10px 20px;
    width: 100%;
    ul {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        * {
            display: contents;
        }
        li {
            &:not(:first-child):before {
                content: "\f0da";
                color: #aaa;
                margin: 0 0.6rem;
                position: relative;
                top: 1px;
                font-family: "FontAwesome";
            }
        }
    }

    .breadcrumb-title {
        color: #999;
    }

    & + .main-big-title {
        margin-top: -15px;
        @include mq-down(lg) {
            margin-top: -10px;
        }

    }

    @include mq-down(lg) {
        padding: 5px 10px;
        font-size: 0.7em;
    }
}

/* lity -----------------------------------------*/

.lity-wrap {
    .lity-container {
        max-width: 85vw;
        max-height: 80vh;
    }
}



/* pagination -----------------------------------------*/

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: #fff;
    font-size: 0.9em;
    border-radius: 3px;
    grid-gap: 8px;
    .page-numbers {
        font-weight: bold;
        color: #ccc;
        width: 34px;
        line-height: 34px;
        text-align: center;
        text-decoration: none;
        border-radius: 3px;
        &.current {
            background: #607d8b;
            color: #fff;
        }
        &.dots {
        }
    }
    a.page-numbers {
        &:hover {
            background: #ccc;
            color: #fff;
        }
        &.prev:before {
            font-family: fontAwesome;
            content: "\f053";
        }
        &.next:before {
            font-family: fontAwesome;
            content: "\f054";
        }
    }
}

a.prev1.page-numbers:before {
    font-family: fontAwesome;
    content: "\f104";
}

a.next1.page-numbers:before {
    font-family: fontAwesome;
    content: "\f105";
}


/* embed -----------------------------------------*/

.youtube-container,
.video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }