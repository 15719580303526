.diver_qa {
    padding: 10px;
    & + & {
        border-top: 1px solid #ccc;
        padding-top: 20px;
    }
}

.diver_question,
.diver_answer {
    position: relative;
    margin-left: 50px;
    min-height: 40px;
    margin-bottom: 10px;
    color: #ff5722;
    font-weight: bold;
    &:before {
        content: "Q";
        background: #e04848;
        position: absolute;
        top: -.25em;
        left: -50px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        line-height: 40px;
        font-size: 21px;
        font-weight: bold;
        color: #fff;
    }
}

.diver_answer {
    color: inherit;
    font-weight: normal;
    &:before {
        top:0;
        content: "A";
        background: #488ce0;
    }
}