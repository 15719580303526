// ----------------------------------------
// settings
// ----------------------------------------


// display ========================================

// base ----------

$light-color:#fff;
$gray-color:#eee;
$gray-light-color:#f9f9f9;
$dark-light-color:#282c33;
$dark-color:#000;


// width ----------

$container-width : 1100px;
$container-max-width: 94%;
$main-inner-width: 800px;
$content-width: 769px;
$side-width: 310px;


// gutter ----------

$xl-gt: 48px;
$lg-gt: 36px;
$md-gt: 24px;
$sm-gt: 12px;

// responsive ----------

// min-width
$breakpoint-up: (
  'sm': 'screen and (min-width: 600px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1000px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

// max-width
$breakpoint-down: (
  'sm': 'screen and (max-width: 599px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 999px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;

@mixin mq-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin mq-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

@mixin responsive-down($class, $bp: $breakpoint-down) {
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

@mixin responsive-up($class, $bp: $breakpoint-up) {
  @each $suffix, $value in $bp {
    @media #{$value} {
      #{$class}-#{$suffix} {
        @content;
      }
    }
  }
}

// font ========================================

$font-awesome: FontAwesome;
$font-sans: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", YuGothic, Verdana, "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;


// size ----------

$text-size-default: 16px;
$text-size-l: 18px;
$text-size-m: 14px;
$text-size-s: 13px;
$text-size-xs: 12px;


// color ========================================

// common -------------

$main-bg-color:      #f9f9f9;
$text-color:    #000;

// border ----------------------------------

$border-color:        #e5e5e5;
$border-color-light:  #eee;


// link ----------------------------------

$link-color: #70b8f1;
$side-link-color: #70b8f1;

// sns ----------

$sns-color: (
  'twitter':#00aced,
  'facebook':#305097,
  'hatebu':#00a4de,
  'feedly':#2bb24c,
  'line':#25af00,
  'pocket':#f03e51,
  'rss':#ffb53c,
  'youtube':#ff0000,
  'instagram':#e6653c,
  'tiktok':#000
  // 'instagram':linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)
);

// auxiliary color --------------------------------

$auxiliary-color: (
  'white':(
    'text':#000,
    'bg':#f9f9f9
  ),
  'black':(
    'text':#fff,
    'bg':#000
  ),
  'blue':(
    'text':#fff,
    'bg':#70b8f1
  ),
  'red':(
    'text':#fff,
    'bg':#ff8178
  ),
  'yellow':(
    'text':#000,
    'bg':#ffe822
  ),
  'orange':(
    'text':#fff,
    'bg':#ffa30d
  ),
  'green':(
    'text':#fff,
    'bg':#2ac113
  ),
  'gray':(
    'text':#000,
    'bg':#ccc
  )
);

// admin bar --------------------------------

$admin-bar-height-lg:   32px;
$admin-bar-height-md:   46px;

// header --------------------------------

$header-bg:         #fff;
$header-text:       #000;


// frontpage --------------------------------

$mainv-bg-1:    #41BAA4;
$mainv-bg-2:    #42A994;
$mainv-color:  #fff;

// content --------------------------------

$content-line-height:   1.5;
$content-line-space:    20px;

// shadow --------------------------------

$box-shadow:0 5px 5px 0 rgba(18,63,82,0.035), 0 0 0 1px rgba(176,181,193,0.2);

// main --------------------------------
// post-cta --------------------------------

// $post-cta-bg:     $dark;
// $post-cta-color:  $base-color;
$post-cta-btn-bg: #F86222;
$post-cta-btn-color: #fff;

$post-meta-bg:      #1F3145;
$post-meta-bg-2:    #1C2B3D;
$post-meta-color:   #fff;

// side --------------------------------

// $side-link-color:   $main-color-3;
// $side-hover-color:  $main-color-3;

$side-title-bg:     #ebebeb;
$side-title-border: #e4e4e4;

// Big footer --------------------------------


// footer --------------------------------

$footer-bg-color:     #3a3a3a;
$footer-text-color:   #fff;
$footer-link-color:   #fff;
$footer-hover-color:  #ccc;


// font --------------------------------

// @font-face {
//   font-display: swap;
//   font-family: 'NotoSansJP';
//   font-style:normal;
//   font-weight: 300;
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.eot'); /* IE9 Compat Modes */
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-DemiLight.woff') format('woff'); /* Modern Browsers */
// }

// @font-face {
//   font-display: swap;
//   font-family: 'NotoSansJP';
//   font-style:normal;
//   font-weight: 700;
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.eot'); /* IE9 Compat Modes */
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   // url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Medium.otf') format('woff'); /* Modern Browsers */
// }

// @font-face {
//   font-display: swap;
//   font-family: 'NotoSansJP';
//   font-style:normal;
//   font-weight: 900;
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Black.eot'); /* IE9 Compat Modes */
//   src: url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//   url('../lib/fonts/NotoSansCJKjp/NotoSansCJKjp-Black.woff') format('woff'); /* Modern Browsers */
// }


// @font-face {
//      font-family: 'Font Awesome Brands';
//      font-style: normal;
//      font-weight: 400;
//      src: url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.13.0/webfonts/fa-brands-400.woff2") format("woff2");
//      font-display: swap;
//  }

//  @font-face {
//      font-family: 'Font Awesome Free';
//      font-style: normal;
//      font-weight: 900;
//      src:url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.13.0/webfonts/fa-regular-400.woff2") format("woff2"),
//          url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.13.0/webfonts/fa-solid-900.woff2") format("woff2");
//      font-display: swap;
//  }


