.diver-firstview{
    position: relative;
    margin:0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    &.type-simple{

    }

    &__bg,
    &__overlay{
        position: absolute;
        top: 0;
        left: 0;
        bottom:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }

    &__content{
        position: relative;
        // width: 75%;
        margin: 0 auto;
        padding: 2.5em 1.5em;
    }
}

.diver-fisrtview-template {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    grid-gap: 30px;
    &.has-image{
        grid-gap: 80px;
    }
    &__meta{
        display: flex;
        flex-direction: column;
        gap: .8em;
    }
    &__title{
        font-weight: bold;
        font-size: 35px;
    }
}