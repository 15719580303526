.diver-tab {
    width: 100%;
    overflow: hidden;
    &__btns{
        display: flex;
        .diver-tab-button {
            padding: 0.5em 1em;
            border-radius: 5px;
            flex: 1 0 auto;
            color: #999;
            background: #eee;
            cursor: pointer;
            text-align: center;
            &.is-active{
                color: #fff;
                background: #333;
                font-weight: bold;
            }
        }

    }
    
    &__panels{
        .diver-tab-panel {
            display: none;
            padding: 1em;
            margin: 0;
            width: 100%;
            &.is-active{
                display: block;
            }
        }
    }

}
