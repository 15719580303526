.diver-review{
    display: flex;
    align-items: center;
    &__label{
        color: #ffce00;
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1;
        &:before{
            content:"(";
        }
        &:after{
            content:")";
        }
    }
}

.review_star {
    vertical-align: sub;
    height: 36px;
    width: 200px;
    background-image: url(../images/diverstar_o.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-repeat: no-repeat;
    display: inline-block;
    & + .review_star_score {
        font-weight: bold;
        padding: 0 0.5em;
        opacity: 0.5;
    }

    .star {
        height: 36px;
        width: 200px;
        background-image: url(../images/diverstar.png);
        background-size: auto 100%;
        background-repeat: no-repeat;
    }
}

.dv_review_box {
    display: block;
    border: 1px solid #ccc;
    padding: 1em;
    .review_title {
        display: inline-block;
    }
    .review_star {
        float: right;
        margin: 0.5em 0;
    }
}
