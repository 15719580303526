/* contace -----------------------------------------*/

.contact > div:after{
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: " ";
    font-size: 0;
}

.contact th {
    vertical-align: middle;
    font-size: 0.8em;
}

.contact td {
    padding: 5px 10px;
}

.contact .form_title {
    width: 20%;
    float: left;
}

.contact .form_area {
    float: right;
    width: 77%;
    margin-bottom: 10px;
}

.contact .form_area textarea{
  width: 100%;
}

.wpcf7 .form_title span {
    font-size: 12px;
    color: #fff;
    background-color: #f44336;
    padding: 3px 6px;
    border-radius: 3px;
    margin-left: 12px;
}

.wpcf7-form-control.wpcf7-submit {
    display: block;
    margin: 0 auto;
    padding: 1em 4em;
}

@media screen and (max-width:768px){
  .contact .form_title,.contact .form_area {
    width: 100%;
    float: none;
  }
}

