.sc-inner-content{
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
}
/* font-size -----------------------------------------*/

.fontsize.\31 {
    font-size: 0.8em;
}
.fontsize.\32 {
    font-size: 0.9em;
}
.fontsize.\33 {
    font-size: 1.1em;
}
.fontsize.\34 {
    font-size: 1.3em;
}
.fontsize.\35 {
    font-size: 1.4em;
}
.fontsize.\36 {
    font-size: 1.5em;
}
.fontsize.\37 {
    font-size: 1.6em;
}
.fontsize.\38 {
    font-size: 1.7em;
}
.fontsize.\39 {
    font-size: 2em;
}
.fontcolor {
    color: red;
}
.fontbackground {
    background: #eee;
    padding: 2px 5px;
}

/* marker -----------------------------------------*/

.sc_marker {
    background: linear-gradient(transparent 50%, #ffe900 50%);
    &.red {
        background: linear-gradient(transparent 50%, #ffd9d9 50%);
    }
    &.blue {
        background: linear-gradient(transparent 50%, #cfdbff 50%);
    }
}

.sc_marker-animation {
    background-image: linear-gradient(to right, transparent 50%, rgb(255, 247, 2) 50%);
    background-repeat: repeat-x;
    background-size: 200% 0.8em;
    background-position: 0 0.5em;
    font-weight: bold;
    &.active {
        background-position: -100% 0.4em;
        transition: 2.5s;
    }

    &.red {
        background-image: linear-gradient(to right, transparent 50%, rgba(255, 0, 0, 0.3) 50%);
    }

    &.blue {
        background-image: linear-gradient(to right, transparent 50%, rgba(100, 149, 237, 0.5) 50%);
    }
}

/* voice review -----------------------------------------*/

.diver_voice {
    .sc_balloon {
        flex: 1;
    }
    &__title {
        font-size: 1.1em;
        border-bottom: 1px solid #ddd;
        padding-bottom: 7px;
    }
    .review_star {
        display: block;
        margin-top: 5px;
    }
}

/* toggle -----------------------------------------*/


body.mce-content-body {
    .sc_toggle_content {
        visibility: visible;
        position: relative;
        height: auto;
    }
}


/* get post -----------------------------------------*/

.editer_diver_kiji {
    margin: 15px 0;
    border: 1px solid #eee;
    &_title {
        font-size: 0.9em;
        padding: 3px 10px;
        &::before {
            content: "\f0b2";
            margin-right: 10px;
            font-family: fontAwesome;
        }
    }
    ul.diver_rel_kiji {
        border-top: 1px solid #eee;
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            line-height: 1.4;
            font-size: 0.8em;
            border-bottom: 1px solid #eee;
            margin: 0;
            a {
                text-decoration: none;
                display: block;
                padding: 6px 12px;
                &::before {
                    content: "\f0da";
                    margin-right: 8px;
                    font-family: fontAwesome;
                }
            }
        }
    }
}

/* user_list -----------------------------------------*/

.user_list {
    display: grid;
    justify-content: center;
    text-align: center;
    .user {
        padding: 10px 15px 20px;
        text-decoration: none;
    }
    .user_avator {
        width: 100%;
        margin-bottom: 5px;
        img {
            border-radius: 50%;
            object-fit: cover;
            margin: 0;
        }
    }
    .user_name {
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #333;
    }
}


/* qr code -----------------------------------------*/

.sc_qrcode {
    text-align: center;
}

/* icon -----------------------------------------*/

.sc_content_icon{
    display: inline-block;
    vertical-align: baseline;
    padding: 0 0.2em;
    line-height: 1;
}

/* popup -----------------------------------------*/

.sc_popup_content {
    background: #fff;
    padding: 1em;
    border-radius: 5px;
    max-width: 80vw;
    margin: 0 auto;
}
