.content {
    line-height: 1.8;
    position: relative;
    word-break: normal;
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
    .sub-text{
        opacity: .6;
        font-size: .9em;
    }
}

i span {
    display: none;
}

:where(.content) {
    img {
        max-width: 100%;
        height: auto;
    }

    .padding0 img {
        margin: 0;
    }

    p.has-background {
        padding: 20px 30px;
    }

    .post-upon {
        padding: 20px;
        padding-top: 0;
        background: #fff;
    }

    ul,
    ol,
    table,
    dl {
        margin-bottom: 1.2em;
    }

    ul {
        list-style-type: disc;
        padding-left: 1.5em;
        line-height: 2;
        li {
            line-height: 1.5;
            margin-bottom: 0.6em;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 1.5em;
        line-height: 2;
    }

    a:not([class]) {
        color: #0033cc;
        word-break: break-all;
    }
}

/* page link -----------------------------------------*/

.content-pager-link {
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 12px;
    font-size: 0.9em;

    .post-page-numbers {
        background: #fff;
        color: #333;
        box-shadow: $box-shadow;
        border-radius: 5px;
        font-weight: bold;
        text-align: center;
        &.current {
            background: #333;
            color: #fff;
        }
        &:not(.current):hover {
            background-color: #eee;
        }
    }

    &__buttons {
        .post-page-numbers {
            width: auto;
            padding: 12px 18px;
            display: inline-block;
            .prev:before {
                font-family: fontAwesome;
                content: "\f053";
            }
            .next:after {
                font-family: fontAwesome;
                content: "\f054";
                margin-left: 8px;
            }
        }
    }
    &__pagination {
        display: flex;
        grid-gap: 5px;
        .post-page-numbers {
            line-height: 36px;
            width: 36px;
        }
    }
}

/* toc -----------------------------------------*/

$toc_color: #333;

.diver-toc {
    $this:&;
    border-radius: 8px;
    margin: 1.5em auto;
    padding: 1em 1.5em;
    font-weight: 900;
    background-color: #f9f9f9;
    color: #333;
    &__title {
        position: relative;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        gap: 1em;
        cursor: pointer;
        &:after {
            content: "\f0d7";
            font-family: "FontAwesome";
            font-size: 1.2em;
            transition: 0.4s;
        }
        &.is-active {
            & + ol {
                margin-top: .5em;
                max-height: 100vh;
                box-shadow: none;
            }
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    // &.is-open-hover:hover #{$this}__title:not(.is-active) + #{$this}__list {
    //     max-height: 500px;
    //     box-shadow: none;
    // }

    ol {
        list-style-type: none;
        margin: 0;
    }
    &__list {
        padding: 0;
        margin: 0;
        max-height: 0;
        counter-reset: toc 0;
        list-style-type: none;
        font-size: 0.9em;
        color: inherit;
        display: block;
        overflow: auto;
        transition: 0.4s;
        li {
            position: relative;
            margin: 0;
            padding: 0;
            a {
                color: inherit;
                text-decoration: none;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                opacity: .5;
                &.is-current{
                    opacity: 1;
                }
            }
        }
        ol {
            counter-reset: toc 0;
            padding-left: 2em;
            li {
                margin-left: 0;
                padding-left: 0;
                a:before {
                    // content: counters(toc," - ") ". ";
                    // counter-increment: toc;
                    content: "\f0da";
                    color: inherit;
                    font-size: 0.8em;
                    margin-right: 7px;
                    opacity: 0.6;
                    font-family: fontAwesome;
                }
            }
        }
        & > li {
            padding: 0.6em 0;
            &:where(:not(:first-child)) {
                border-top: 2px dotted;
            }
            & > a {
                &:before {
                    display: inline-block;
                    counter-increment: toc 1;
                    content: counters(toc, " - ") "";
                    width: 28px;
                    text-align: center;
                    line-height: 28px;
                    border-radius: 50%;
                    font-weight: bold;
                    margin-right: 10px;
                    letter-spacing: 0;
                    font-size: 0.8em;
                    background: $toc_color;
                    color: #fff;
                }
            }
        }
    }
    @include mq-down(lg){
        font-size: .9em;
    }
}