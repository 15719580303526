.sc_toggle_box {
    position: relative;
    box-shadow: 0 5px 5px 0 rgb(18 63 82 / 4%), 0 0 0 1px rgb(176 181 193 / 20%);
    border-radius: 3px 3px 0 0;
    background: #fff;
    color: #000;
    margin-bottom: 1.2em;
    .sc_toggle_title {
        padding: 10px 20px;
        position: relative;
        padding-right: calc(20px + 1.5em);
        cursor: pointer;
        &.active {
            &:before {
                content: "";
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid #fff;
                position: absolute;
                bottom: -10px;
                left: 30px;
            }
            &:after {
                content: "\f077";
                font-family: fontAwesome;
            }
            & + .sc_toggle_content {
                visibility: visible;
                height: auto;
                padding: 20px;
                position: initial;
            }
        }
        &:after {
            content: "\f078";
            right: 0.5em;
            top: 50%;
            position: absolute;
            color: #e0e0e0;
            font-family: fontAwesome;
            transform: translateY(-50%) translateX(-50%);
        }
    }
    .sc_toggle_content {
        visibility: hidden;
        height: 0;
        position: fixed;
        background: #f9f9f9;
        box-shadow: 0 0px 5px rgb(120 120 120 / 10%) inset;
        & > *:first-child {
            margin-top: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    & + & {
        border-radius: 0 0 3px 3px;
        border-top: none;
        margin-top: -1.2em;
    }
}