@charset "utf-8";

// @use "../global" as *;
@use 'sass:math';
@use "module/setting" as *;
@import "module/setting";

@import "module/normalize";
@import "module/wp";
@import "module/content";

@import "module/common";
@import "module/auxiliary";
@import "module/column";

@import "module/auxiliary/badge";
@import "module/auxiliary/button";
@import "module/auxiliary/designlist";
@import "module/auxiliary/frame";
@import "module/auxiliary/heading";
@import "module/auxiliary/ranking";
@import "module/auxiliary/tab";
@import "module/auxiliary/hr";
@import "module/auxiliary/balloon";
@import "module/auxiliary/reviewbox";
@import "module/auxiliary/reviewtable";
@import "module/auxiliary/voice";
@import "module/auxiliary/qa";
@import "module/auxiliary/toggle";
@import "module/auxiliary/star";
@import "module/auxiliary/barchart";

@import "module/layout";
@import "module/sns";
@import "module/header";
@import "module/footer";
@import "module/slider";
@import "module/archive";
@import "module/single";
@import "module/comment";
@import "module/firstview";
@import "module/widget";
@import "module/block";
@import "module/plugin";
@import "module/color";

/****************************************

          effect
          
*****************************************/
.effect i {
  height: 120px;
  padding: 20px;
  font-size: 100px;
  transition: 0.8s;
}

.effect div {
  margin: auto;
  transition: 0.8s;
  transition-delay: 0.8s;
}

.space-del {
  letter-spacing: -0.4em;
}

/****************************************

          Sidebar

*****************************************/


.cat-item .count,
.widget li a .count {
  float: right;
  background: #eee;
  color: #666;
  padding: 1px 0.5em;
  display: inline;
  border-radius: 2px;
}

:where(.widget_archive, .widget_categories) select {
  cursor: pointer;
  appearance: none;
  width: 100%;
  padding: 1em;
  color: inherit;
  border: 1px solid #ccc;
  background: transparent;
  border-radius: 0;
  font-size: 0.8em;
  &::-ms-expand {
    display: none;
  }
}

/****************************************

          shortcode

*****************************************/

.sc_frame_title.titlenone {
  display: none;
}

@media screen and (max-width: 768px) {
  .sc_frame {
    padding: 0.6em 1em;
  }

  .sc_frame_title {
    padding: 0.3em 1em;
  }
}

@media screen and (max-width: 480px) {
  .voice .text {
    width: 70%;
  }
  .voice .name {
    font-size: 0.6em;
  }

  .voice .think_balloon.text {
    width: 60%;
  }

  .voice .icon img {
    width: 50px;
    height: 50px;
  }
}