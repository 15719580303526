@import "../setting";

.diver-review-box {
    $this: &;
    box-shadow: $box-shadow;
    border-radius: 10px;
    max-width: 500px;
    margin: calc(1.5em + 60px) auto 1.5em;
    padding: 1.5em;
    text-align: center;
    // &__author {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    &__image {
        margin: calc(-1.5em - 50px) auto 1em;
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 50%;
    }
    &__name {
        font-size: 0.8em;
        opacity: .6;
        line-height: 1;
    }
    & > .diver-review {
        justify-content: center;
    }
    &__title {
        font-weight: bold;
    }
    &__content {
        text-align: left;
        font-size: 0.9em;
        margin: 1em 0 0;
        padding: 1em;
        display: flex;
        background-color: transparent;
        box-shadow: none;
        justify-content: center;
        align-items: center;
        &:before {
            top: -10px;
            left: -10px;
        }
        &:after {
            bottom: -10px;
            right: -10px;
        }
    }
}

// deprecated ------------------------------------

.diver_voice_wrap {
    margin: 20px 0 30px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    .diver_voice_icon {
        max-width: 100%;
        border-radius: 5px;
        object-fit: cover;
        font-family: "object-fit:cover;";
        box-shadow: none;
        border: none;
    }
    .diver_voice_title {
        font-size: 1.1em;
        border-bottom: 1px solid #ddd;
        padding-bottom: 7px;
        line-height: 1;
        .review_star {
            display: block;
            margin-top: 5px;
        }
    }

    .diver_voice_content {
        line-height: 1.5;
        padding: 5px 0;
        font-size: 0.9em;
    }
    .diver_voice_name {
        font-size: 0.9em;
        color: #f17e00;
        text-align: right;
        line-height: 1;
    }
    @include mq-down(md) {
        flex-direction: column;
        .diver_voice_icon {
            width: 60%;
            margin: 0 auto;
        }
    }
}

.diver_voice {
    flex: 1;
    position: relative;
    padding: 10px 15px;
    color: #19283c;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    z-index: 0;
    box-shadow: 0 4px 4px rgba(130, 130, 130, 0.5);
}
