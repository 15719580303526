@import "../setting";

.voice {
    margin-bottom: 1.2em;
    display: flex;
    align-items: flex-start;
    grid-gap: 18px;
    .icon {
        text-align: center;
        flex: none;
        img,
        amp-img {
            border-radius: 50%;
            border: 2px solid #eee;
            max-width: 70px;
            width: 70px;
            height: 70px;
            object-fit: cover;
            font-family: "object-fit:cover;";
            margin: 0;
            overflow: hidden;
            &.inverted {
                transform: scale(-1, 1);
            }
        }
        .name {
            line-height: 1;
            font-size: 0.8em;
        }
        & > p {
            margin: 0;
        }
    }

    .sc_balloon {
        padding: 0.5em 1em;
        margin: 0;
        font-size: 0.9em;
        margin-top: 15px;
    }

    &.right {
        flex-direction: row-reverse;
        // .sc_balloon {
        //     &:before {
        //         border-width: 0px 0 17px 15px;
        //         margin-top: -11px;
        //         right: -9px;
        //         top: 11px;
        //     }
        //     &:after {
        //         border-width: 0 0 14px 12px;
        //         right: -13px;
        //         top: 8px;
        //     }
        // }

        .think_balloon {
            &::before {
                right: -25px;
            }
            &::after {
                right: -15px;
            }
        }
    }

    &.left {
        .sc_balloon {
            // &:before {
            //     border-width: 0px 17px 15px 0;
            //     margin-top: -11px;
            //     left: -8px;
            //     top: 11px;
            // }
            // &:after {
            //     border-width: 0 13px 12px 0px;
            //     left: -13px;
            //     top: 8px;
            // }
        }

        .think_balloon {
            &::before {
                left: -26px;
            }
            &::after {
                left: -16px;
            }
        }
    }
}
// .content .voice p {padding: 0;}

// .voice.left.n_bottom .icon{float: left;}
// .voice.left.n_bottom .icon img{float: none;}
// .voice.left.n_bottom .icon .name{
//   margin-right: 20px;
//   text-align: center;
//   line-height: 1;
//   max-width: 70px;
//   margin-top: -10px;
// }
// .voice.right.n_bottom .icon{float: right;}
// .voice.right.n_bottom .icon img{float: none;}
// .voice.right.n_bottom .icon .name{
//   margin-left: 20px;
//   text-align: center;
//   line-height: 1;
//   max-width: 70px;
//   margin-top: -10px;
// }
