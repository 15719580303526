@import "../setting";

.sc_frame_wrap {
    margin-bottom: 1.2em;
    position: relative;
    .sc_frame_title {
        padding: .25em 1em .2em;
        font-size: .9em;
        position: relative;
        font-weight: bold;
        color: #fff;
        background: #ccc;
        top: 1px;
        i {
            margin-right: 0.2em;
        }
    }
    .sc_frame_text{
        margin: 0;
    }
    .sc_frame {
        overflow: auto;
        border: 3px solid #ccc;
        padding: 1em 1.5em;
        position: relative;
        border-radius: 5px;
        background: #fff;
        color: #000;
        margin: 0;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }

        &.no-border{
            border: none;
        }

        &.shadow {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        }

        &.note {
            background-image: linear-gradient(
                    90deg,
                    rgba(204, 204, 204, 0) 0%,
                    rgba(201, 204, 204, 0) 49%,
                    rgba(255, 255, 255, 100) 50%,
                    rgba(255, 255, 255, 100) 100%
                ),
                linear-gradient(
                    180deg,
                    rgba(204, 204, 204, 0) 0%,
                    rgba(204, 204, 205, 0) 96.5%,
                    rgba(30, 30, 30, 100) 100%
                );
            background-repeat: repeat-x, repeat-y;
            background-size: 4px 100%, 100% 1.8em;
            padding: 1.8em 1.5em;
            line-height: 1.8em;
            & > p{
                line-height: 1.8em;
            }
        }

        &.tape:before {
            content: "";
            position: absolute;
            top: 17px;
            width: 25%;
            height: 35px;
            opacity: 0.15;
            margin: -35px auto 10px 35%;
            background: #4e4e4e;
            transform: rotate(-5deg);
            left: 10px;
            right: 10px;
        }
    }

    .sc_frame_icon {
        display: inline-block;
        font-size: 1em;
        margin-right: .6em;
    }

    .sc_frame_before {
        position: absolute;
        color: #fff;
        background: #ccc;
        left: 5px;
        font-weight: bold;
        bottom: 100%;
        padding: 0.3em 1.5em;
        border-radius: 3px 3px 0 0;
        font-size: 0.8em;
    }

    /*----- style -------------------*/

    &.normal {
        .sc_frame {
            border-radius: 5px;
            &:not(.no-title){
                border-radius: 0 0 5px 5px;
                border-top: none;
            }
        }
    }

    &.solid{
        .sc_frame {
            background-color: #ccc;
            border: none;
        }
    }

    // .sc_frame_title.inline,
    &.inline {
        .sc_frame_title {
            display: inline-block;
            border-radius: 5px 5px 0 0;
        }
        .sc_frame{
            border-top-left-radius: 0;
        }
    }

    &.inframe {
        .sc_frame_title {
            float: left;
            border-radius: 5px 0 5px 0;
            z-index: 1;
        }

        .sc_frame {
            overflow: inherit;
            &:not(.no-title){
                padding-top: 3em;
            }
        }
    }

    &.block {
        .sc_frame {
            border-radius: 0 0 5px 5px;
            border-top: none;
        }
        .sc_frame_title {
            border-radius: 5px 5px 0 0;
        }
    }

    &.bottom {
        display: flex;
        flex-direction: column;
        .sc_frame_title {
            order: 1;
            border-radius: 0 0 5px 5px;
        }
        .sc_frame {
            border-radius: 5px;
            &:not(.no-title){
                 border-radius: 5px 5px 0 0;
                border-bottom: none;
            }
        }
    }

    &.onframe {
        .sc_frame_title {
            position: absolute;
            display: block;
            top: auto;
            bottom: calc(100% - 1em);
            max-width: calc(100% - 4em);
            left: 1em;
            z-index: 1;
            padding: 1px 1em;
            border-radius: 5px;
            background: #fff;
        }
    }


    .sc_frame_text p:last-child {
        padding-bottom: 0 !important;
    }

    .content .sc_frame.note .sc_frame_text p {
        padding: 0 0 1.8em;
    }

    /*----- color -------------------*/

    @each $key, $color in $auxiliary-color {
        &.#{$key} {
            .sc_frame {
                border-color: map-get($color, 'bg');
                &.has-bg{
                    background-color: rgba(map-get($color, 'bg'), 0.3);
                }
            }
            .sc_frame_title {
                color:map-get($color, 'text');
                background-color: map-get($color, 'bg');
            }
            &.solid{
                .sc_frame {
                    background-color: rgba(map-get($color, 'bg'), 0.3);

                }
            }
            &.onframe{
                .sc_frame_title {
                    color:map-get($color, 'bg');
                    background-color: map-get($color, 'text');
                }
            }
        }
    }

}


.content .sc_frame_title p {
    padding: 0;
    margin: 0;
    display: inline-block;
}

.sc_frame{
    & > *:first-child {
        margin-top: 0;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
    &.aside{
        font-size: .9em;
        color: #545454;
        background: #eee;
        margin-bottom: 1.5em;
        padding: 1em 1.5em;
        &:before{
            font-family:"fontAwesome";
            content:"\f05a";
            margin-right: .6em;
        }
        &.error{
            color: #e11a1a;
            background: #ffdada;
            &::before{
                content:"\f071";
            }
        }
        &.warning{
            color: #333;
            background: #fff794;
            &::before{
                content:"\f06a";
            }
        }
    }
}
