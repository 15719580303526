
:where(.content){
    div:not([class]):not([style]),
    & > [class^="wp-container-"],
    & > [class^="wp-block"]:not(.wp-block-dvaux-section){
        z-index: 2;
        position: relative;
        min-height: 1em;
        margin-bottom: 1.5em;
    }
}

  /* widget / ------------------------*/


.widget.widget_block h2,
.widget.widget_block h3 {
    position: relative;
    font-size: 16px;
    padding: 8px 15px;
    margin: 10px -10px;
}

.widget.widget_block .wp-block-group h2:first-child,
.widget.widget_block .wp-block-group h3:first-child {
    margin: -10px;
    margin-bottom: 10px;
}

/* search / ------------------------*/

.wp-block-search {
    $this: &;

    & &__label {
    }

    & &__input,
    & &__button {
        background: #fff;
        border: none;
        border-radius: 3px;
        box-shadow: $box-shadow;
    }

    & &__input {
        height: 38px;
        padding: 10px 15px;
        flex:1;
    }
    & &__input,
    & &__button {
        font-size: 0.9em;
    }

    &#{$this}__no-button {
        #{$this}__inside-wrapper {
            align-items: center;
            &:before {
                content: "\f002";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                color: #ccc;
                left: 10px;
            }
            #{$this}__input {
                padding-left: 32px;
            }
        }
    }

    &#{$this}__button-inside {
        #{$this}__inside-wrapper {
            background: #fff;
            color: #333;
            border-radius: 5px;
            box-shadow: $box-shadow;
            border: none;
            #{$this}__input {
                padding: 0 1em;
                background: transparent;
                box-shadow: none;
            }
            #{$this}__button {
                margin: 0;
                background: #f9f9f9;
            }
        }
    }
}

/* columns / ------------------------*/

.content .wp-block-columns {
    display: flex;
    justify-content: center;
}

.content .wp-block-column {
    margin: 0 10px;
    margin-bottom: 1em;
}

@media (max-width: 769px) {
    .content .wp-block-column {
        margin-right: 0;
        margin-left: 0;
        width: 50%;
        padding: 0 0.5em;
    }

    .wp-block-columns.wp-block-column:nth-child(odd) {
        padding-right: 0.5em;
    }

    .wp-block-columns.wp-block-column:nth-child(even) {
        padding-left: 0.5em;
    }
}

/* image / ------------------------*/

.wp-block-image figcaption {
    margin: 0;
}

/* section / ------------------------*/

.dvaux_section_environ {
    margin: 0 -40px;
    padding-bottom: 60px;
    overflow: hidden;
}

.dvaux_section_environ {
    padding-bottom: 60px;
}

.dvaux_section_inner {
    padding: 40px;
    position: relative;
}

.dvaux_section_inner > *:not(.section_filter) {
    z-index: 2;
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
}

.dvaux_section_environ:not(.normal) + .dvaux_section_environ > .dvaux_section_inner {
    padding-top: 80px;
}

.dvaux_section_environ + .dvaux_section_environ {
    margin-top: -60px;
}

.dvaux_section_inner .section_filter {
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

/* environ / ------------------------*/

@media screen and (max-width: 1200px) {
    .dvaux_section_environ {
        margin: 0 -15px;
    }
    .dvaux_section_inner {
        padding: 40px 15px;
    }
}

@media screen and (max-width: 768px) {
    .dvaux_section_environ {
        margin: 0 -10px;
    }
    .dvaux_section_inner {
        padding: 40px 10px;
    }
}

.dvaux_section_inner::after {
    position: absolute;
    content: "";
    pointer-events: none;
    z-index: 1;
}

.dvaux_section_environ.balloon .dvaux_section_inner::after {
    bottom: -35px;
    z-index: 10;
    background: inherit;
    left: 50%;
    width: 70px;
    height: 70px;
    transform: translateX(-50%) rotate(45deg);
}

.dvaux_section_environ.slope1 .dvaux_section_inner::after {
    bottom: -50px;
    left: -5%;
    right: 0px;
    width: 110%;
    height: 20%;
    min-height: 60px;
    background: inherit;
    transform-origin: left center;
    transform: rotate(-3deg);
}
.dvaux_section_environ.slope2 .dvaux_section_inner::after {
    bottom: 0px;
    left: -5%;
    right: 0px;
    width: 110%;
    height: 20%;
    min-height: 60px;
    background: inherit;
    transform: rotate(3deg);
}

.dvaux_section_environ.radius .dvaux_section_inner::after {
    bottom: -30px;
    left: 0;
    width: 100%;
    height: 60px;
    background: inherit;
    border-radius: 0 0 50% 50%;
}

.dvaux_section_environ.zigzag .dvaux_section_inner {
    padding-bottom: 60px;
    margin-bottom: -30px;
    position: relative;
    -webkit-mask-image: linear-gradient(0deg, transparent 30px, #fff 30px),
        linear-gradient(-135deg, #fff 15px, transparent 15px), linear-gradient(135deg, #fff 15px, transparent 15px);
    -webkit-mask-position: left bottom;
    -webkit-mask-repeat: repeat-x;
    -webkit-mask-size: 100% 100%, 30px 30px, 30px 30px;
    z-index: 1;
}

/* icon / ------------------------*/

.dvaux_icon {
    max-width: 200px;
    margin: 0 auto;
    padding: 40px;
    margin-bottom: 1.2em;
}

/* iconbox / ------------------------*/

.dvaux_iconbox {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 1em;
    position: relative;
}

.dvaux_iconbox_meta {
    padding: 0 1em 1em 1em;
}

.dvaux_iconbox .dvaux_icon {
    padding: 40px 0px;
    margin-bottom: 1em;
    max-width: 100%;
}

.dvaux_iconbox--icon {
    margin: 10px 0;
}

.dvaux_iconbox .title {
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 0 10px 0;
    padding: 0;
    background: none;
    color: #000;
    line-height: 1.5;
}

.dvaux_iconbox .textcontent {
    text-align: center;
    font-size: 0.9em;
}

.dvaux_iconbox .wp-block-image img {
    border: none;
}

.wp-block-column .dvaux_iconbox {
    height: 100%;
}

.wp-block-image img {
    margin-bottom: 0;
}
