/* footer -----------------------------------------*/

#footer {
    background: #999;
    box-shadow: $box-shadow;
    .footer_content {
        font-size: 0.9em;
        margin: 0 auto;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq-down(md) {
            flex-direction: column;
        }
    }
    #copyright {
        margin: 0;
        padding: .8em 0;
    }
}

.footer_navi{
    @include mq-down(md){
        justify-content: center;
    }
}

/* footer big -----------------------------------------*/

#bigfooter {
    .bigfooter-inner {
        display: flex;
        margin: 0 auto;
        padding: 20px 0;
        grid-gap: 20px;
        &__column {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1em;
        }

        .footer_title {
            font-weight: bold;
            margin-bottom: 0.8em;
        }

        li a{
            padding: 0.8em 0;
        }

        @include mq-down(md){
            flex-direction: column;
        }
    }
}

// .footer_title {
//     padding: 5px;
//     margin-bottom: 5px;
// }

// #bigfooter li{
//     padding: 5px;
// }

#bigfooter ul ul li {
    padding-right: 0;
}

#bigfooter ul ul.children {
    padding-top: 5px;
}

#bigfooter ul li:before {
    content: none;
}

/* cta -----------------------------------------*/

.footer-cta {
    display: block;
    visibility: hidden;
    position: fixed;
    bottom: -50%;
    width: 100%;
    z-index: 99;
    transition: 0.8s;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    &.is-active,
    &.is-show {
        bottom: 0;
        visibility: visible;
    }
    &.is-hidden {
        bottom: -50%;
    }

    &__inner {
        color: inherit;
        padding: 10px;
        position: relative;
        display: flex;
        align-items: center;
        grid-gap: 1em;
        max-height: 120px;
    }

    &__meta {
        flex: none;
        max-width: 50%;
        padding: 0 1em;
        .footer-cta-title {
            font-weight: bold;
            font-size: 1.4em;
        }
        .footer-cta-desc {
            font-size: 0.9em;
        }
    }
    &__img {
        position: relative;
        height: 100px;
        flex: 1 0 auto;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: right;
        }
    }

    #footer-cta-close {
        background-color: inherit;
        color: inherit;
        opacity: 0.5;
        font-size: 1.5em;
        line-height: 35px;
        width: 35px;
        text-align: center;
        position: absolute;
        bottom: 100%;
        left: 0;
        cursor: pointer;
    }

    @include mq-down(lg) {
        .footer-cta__meta {
            display: none;
        }
        .footer-cta__img {
            max-width: 100%;
            img{
                object-position: center;
            }
        }
    }

    @include mq-down(md) {
        display: none;
    }
}

#footer-cta-open {
    font-size: 20px;
    color: #fff;
    bottom: -50px;
    left: 15px;
    position: fixed;
    line-height: 40px;
    text-align: center;
    padding: 0px 15px;
    border-radius: 5px;
    opacity: 0.5;
    font-weight: bold;
    z-index: 99;
    cursor: pointer;
    &.is-active {
        bottom: 15px;
    }
}

// @media screen and (max-width:1201px){
//   #footer_cta {
//     padding:.3em;
//     line-height: 0;
//   }

//   #footer_cta figure {
//     height: 100%;
//     width: 100%;
//     float: right;
//   }

//   #footer_cta .footer_cta_meta{
//     display: none;
//   }

//   #footer_cta .footer_cta_wrap{
//     float: right;
//   }

//   #footer_cta a.close {
//     font-size: 30px;
//     width: 30px;
//     line-height: 88px;
//     position: absolute;
// }
// }

// @media screen and (max-width:768px){

//   #footer_cta a.close {
//       font-size: 20px;
//       width: 20px;
//       line-height: 20px;
//       top: -20px;
//       left: 0;
//       background: #333;
//   }
// }

/* btns -----------------------------------------*/

.footer-btns {
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
    background: #fff;
    color: #555;
    &__item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 8px;
        color: inherit;
        border-left: 1px solid #eee;
        height: 60px;
        i {
            font-size: 1.2em;
        }

        @include mq-down(sm) {
            height: auto;
            padding:.8em 0 .6em;
            grid-gap: 2px;
            flex-direction: column;
        }
    }
    &__title {
        font-size: 0.6em;
    }

    @include mq-up(md) {
        display: none;
    }

    // &.is-active {
    //     bottom: 0;
    // }
}

.lity-container {
    width: 100%;
}

/* top scroll -----------------------------------------*/

#page-top {
    display: block;
    position: fixed;
    text-align: center;
    bottom: -100px;
    right: 20px;
    z-index: 999;
    background: #000;
    color: #fff;
    width: 45px;
    line-height: 45px;
    text-emphasis: center;
    border-radius: 50%;
    transition: 0.4s;
    opacity: 0.5;
    cursor: pointer;
    &:before {
        content: "\f077";
        font-family: "fontAwesome";
    }
    &:hover {
        opacity: 0.7;
    }
    &.is-active {
        bottom: 20px;
    }

    @include mq-down(md) {
        right: 10px;
    }
}
