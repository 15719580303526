.widget {
    position: relative;
    .widget-inner {
        padding: 10px;
        background: #fff;
        font-size: 0.9em;
        &:empty {
            display: none;
        }
        &:not(:empty) + div {
            margin-top: -20px;
        }
    }
    .main-title {
        font-size: 1em;
        margin-bottom: 12px;
    }
}

.widget_block .wp-block-group :where(h2, h3):first-child,
.widgettitle {
    position: relative;
    font-size: 1em;
    padding: 8px 15px;
}
.widget_block .wp-block-group :where(h2, h3):first-child {
    margin: 0 0 10px 0;
}

.widget ul:first-of-type,
.widget ul:first-child {
    margin: -10px;
    font-weight: normal;
    & > li {
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);
        ul {
            margin: 0;
            padding: 0;
            li {
                border: none;
            }
        }
        &:last-child {
            border-bottom: none;
        }
    }
}

.widget_text ul li i {
    vertical-align: middle;
    margin-right: 8px;
}

.textwidget {
    font-size: 0.9em;
    img {
        display: block;
        margin: 0 auto;
    }
    & > *{
        &:first-child{
            margin-top: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

/****************************************

          widget

*****************************************/

:where(.widget_text, .widget_categories, .widget_recent_entries, .widget_nav_menu, .widget_pages, .widget_archive, .widget_meta) {
    ul {
        margin: -10px;
        li {
            a {
                display: block;
                padding: 0.8em 1em;
                margin: 0;
                position: relative;
            }
            ul {
                padding: 0;
                margin: 0;
                position: relative;
                top: -0.4em;
                font-size: 1.3rem;
                li {
                    margin: 0;
                    padding: 0;
                    a {
                        padding: 0.4em 1em;
                        &:before {
                            content: "\f0da";
                            color: #ccc;
                            margin-right: 0.6em;
                            font-family: fontAwesome;
                        }
                    }
                }
                ul {
                    top: auto;
                }
            }
        }
    }
}

.widget.widget_recent_entries li span.post-date {
    font-size: 0.8em;
}

.widget_nav_menu .menu_desc {
    display: none;
}

.diver_widget_adarea {
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 8px;
    .diver_ad {
        flex: 1;
    }
}

.diver_widget_adlabel {
    font-size: 0.8em;
    text-align: center;
}

// .textwidget h2 {
//     padding: 0.7em 1em;
//     font-size: 1em;
//     margin: 1em 0 1em;
// }

// .textwidget h3 {
//     padding: 0.3em 1em;
//     margin: 1em 0 0.8em;
//     font-size: 1em;
//     border-bottom: 1px solid #555;
//     color: #333;
//     background: #fff;
// }

// .textwidget h4 {
//     position: relative;
//     position: relative;
//     font-weight: bold;
//     font-size: 1em;
//     padding: 0.2em 1em;
//     color: #333;
//     margin: 0.8em 0 0.6em;
// }

// .textwidget h4 {
//     color: #333;
//     font-size: 1em;
//     font-weight: bold;
//     margin: 0.6em 0 0.8em;
// }

// .widget .single_title {
//     margin: -10px;
//     margin-bottom: 15px;
// }

.widget-h2 {
    margin: 1em 0;
}

.widget-h2 .widgettitle {
    margin: 0;
}

.widget_rss ul li a {
    padding: 5px 10px;
    display: inline-block;
    font-size: 0.85em;
}

.widget li.recentcomments {
    padding: 10px;
    font-size: 0.9em;
}

.recentcomments > a {
    font-weight: bold;
}

.widget ul.sub-menu .menu_title {
    display: inline-block;
}

a.diver_widget_morelink::after {
    font-family: fontAwesome;
    content: "\f105";
    margin-left: 10px;
    font-weight: bold;
}

/* calendar -----------------------------------------*/

.calendar_wrap {
    .wp-calendar-table {
        width: 100%;
        caption {
            padding: 5px;
        }
        td {
            padding: 5px 0;
            text-align: center;
            a {
                color: $side-link-color;
            }
        }
        thead {
            th {
                padding: 5px;
                text-align: center;
                font-weight: normal;
                color: #000;
                background-color: #eee;
            }
        }
    }
    .wp-calendar-nav {
        display: flex;
        justify-content: space-between;
        font-size: 0.8em;
        a {
            display: inline-block;
            padding: 6px 10px;
        }
    }
}

/* list -----------------------------------------*/

.wp-block-archives,
.wp-block-latest-comments,
.wp-block-latest-posts,
.wp-block-categories-list {
    margin: -10px;
    li {
        padding: 8px 12px;
    }
}

/* Search widget -----------------------------------------*/

#sidebar .widget_search.widget .searchform {
    margin: -10px;
}
#sidebar form.search-widget {
    padding: 5px 10px;
}


.search-widget {
    padding: 10px;
    &__col {
        margin-bottom: 10px;
    }
    &__ipnut {
        width: 100%;
        padding: 10px 8px;
        border: 2px solid #eee;
    }
    &__label {
        width: 100%;
        display: block;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 5px;
    }
    &__select {
        overflow: hidden;
        width: 100%;
        position: relative;
        border-radius: 2px;
        border: 2px solid #eee;
        background: #ffffff;
        &::before {
            position: absolute;
            top: 0.8em;
            right: 0.8em;
            width: 0;
            height: 0;
            padding: 0;
            content: "";
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #999;
            pointer-events: none;
        }
        select {
            width: 100%;
            padding-right: 1em;
            cursor: pointer;
            text-indent: 0.01px;
            text-overflow: ellipsis;
            border: none;
            outline: none;
            background: transparent;
            background-image: none;
            box-shadow: none;
            appearance: none;
            padding: 8px 38px 8px 8px;
            color: #666;
            &::-ms-expand {
                display: none;
            }
        }
    }
    &__checkbox {
        display: none;
        &-label {
            font-size: 13px;
            padding-left: 20px;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            display: inline-block;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                border: 2px solid #ccc;
            }
        }
        &:checked + .search-widget__checkbox-label {
            color: #3c82ff;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: -4px;
                left: 5px;
                width: 7px;
                height: 14px;
                transform: rotate(40deg);
                border-bottom: 3px solid #3c82ff;
                border-right: 3px solid #3c82ff;
            }
        }
    }
    &__submit {
        margin: 0 auto;
        width: 100%;
        padding: 8px 13px 7px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.1em;
        background: #3c82ff;
        color: #fff;
        border: none;
        margin-top: 5px;
    }
}

form.searchform {
    position: relative;
    display: flex;
    input[name="s"] {
        background: #fff;
        color: #000;
        padding: 14px 12px;
        width: 100%;
        font-size: .9em;
        flex: 1;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    input[type="submit"].searchsubmit {
        width: 45px;
        background: url(../images/search_w.png) no-repeat 50% 50%;
        background-size: 17px;
        background-color: #3c74ad;
        border-left: none;
        border-radius: 0 3px 3px 0;
    }
}


/* profile -----------------------------------------*/

.diver_widget_profile {
    .coverimg {
        text-align: center;
        position: relative;
        padding: 0 1em;
        img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 5px solid #fff;
            overflow: hidden;
            object-fit: cover;
            font-family: "object-fit:cover;";
        }
        &.on {
            background-size: 100% auto;
            height: 180px;
            margin-bottom: 68px;
            img {
                position: relative;
                top: 120px;
            }
        }
        &.no img {
            border: 5px solid #fff;
            width: 160px;
            height: 160px;
        }
    }

    .img_meta {
        text-align: center;
        margin-bottom: 10px;
        .name {
            font-weight: bold;
            line-height: 1.2;
        }
        .diver-sns-btns {
            margin-top: 0.5em;
        }
    }

    .meta {
        padding: 0.5em 1em;
        font-size: 0.9em;
    }
    .button {
        text-align: center;
        margin-top: 10px;
        a {
            padding: 0.5em;
            background-color: #eee;
            border-radius: 2px;
            width: 80%;
            font-size: 0.8em;
        }
    }
}

@media screen and (max-width: 959px) {
    .diver_widget_profile .coverimg.on {
        background-size: 100%;
    }
}

/* tab -----------------------------------------*/

.widget_diver_widget_tab {
    .widget-inner {
        padding: 0;
    }
}

.diver-tabwidget {
    width: 100%;
    &__tabs {
        display: flex;
        overflow-x: auto;
        &--item {
            flex: 1 0 auto;
            padding: 0.6em 1.2em;
            background: #f9f9f9;
            color: #999;
            border-top: 3px solid #aaa;
            font-size: 0.9em;
            white-space: nowrap;
            text-align: center;
            box-shadow: 0 -5px 15px -5px rgb(0 0 0 / 10%) inset;
            cursor: pointer;
            &.is-active {
                background: #fff;
                color: #333;
                border-top: 3px solid #2196f3;
                box-shadow: none;
                cursor: default;
            }
        }
    }
    &__content {
        &--title {
            display: none;
        }
        &--inner {
            width: 100%;
            display: none;
            padding: 10px;
            &:first-of-type {
                display: block;
            }
        }
    }
}

// .widget_diver_widget_tab {
//     & > .widget-inner{
//         display: flex;
//         flex-wrap: wrap;
//         background-color: #fff;
//         padding: 0;
//     }
//     & > .widgettitle {
//         width: 100%;
//         order: -2;
//         margin: 0;
//     }
//     .diver-tabwidget__panel {
//         display: contents;
//         & > *:last-child {
//             width: 100%;
//             padding: 10px;
//             display: none;
//         }
//     }
//     .diver-tabwidget__title {
//         order: -1;
//         flex: 1;
//         padding: 0.8em;
//         background: #f9f9f9;
//         color: #999;
//         border-top: 3px solid #aaa;
//         font-size: 0.9em;
//         white-space: nowrap;
//         text-align: center;
//         box-shadow: 0 -5px 15px -5px rgb(0 0 0 / 10%) inset;
//         cursor: pointer;
//         &.is-active {
//             background: #fff;
//             color: #333;
//             border-top: 3px solid #2196f3;
//             box-shadow: none;
//             cursor: default;
//             & + * {
//                 display: block;
//             }
//         }
//     }
// }

/* toc -----------------------------------------*/

.widget {
    &.widget_diver_widget_toc .widget-inner{
        padding: 0;
    }
    .diver-toc {
        border: none;
        margin: 0;
        padding: 0;
        max-height: 380px;
        overflow: auto;
        line-height: 2;
        background-color: transparent;

        &__list {
            padding: 0;
            margin: 0;
            box-shadow: none;
            max-height: 300px;
            & > li {
                padding: 0.8em 1em;
                border-top-width: 1px;
                & > a {
                    &:before {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        font-size: .6em;
                        text-indent: -2px;
                        letter-spacing: -1px;
                    }
                }
            }
        }
    }
}
