.diver-sns-btns {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: flex;
    grid-gap: 6px;
    justify-content: center;
    font-size: 0.7em;
    a {
        font-weight: bold;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 0.5em;
        color: #fff;
        fill: #fff;
        text-align: center;
        text-decoration: none;
        border-radius: 3px;
        flex: 1;
        height: 38px;

        .sns_count {
            font-weight: bold;
            display: inline-block;
            line-height: 1.5em;
            padding: 0 3px;
            margin: 0 0.2em;
            border-radius: 3px;
        }
        .sns_name {
            @include mq-down(md) {
                display: none;
            }
        }
        .sns_icon {
            width: 16px;
            height: 16px;
            fill: inherit;
        }
    }

    &.is-color-bk {
        a.sns {
            background: #000;
        }
    }

    @each $key, $color in $sns-color {
        a.#{$key} {
            background-color: $color;
        }
        &.color-type-text {
            a.#{$key} {
                background-color: transparent;
                color: $color;
                fill: $color;
            }
        }
    }

    &.is-color-bk {
        a.sns {
            background: #000;
        }
        &.color-type-text {
            a.sns {
                background-color: transparent;
                color: #000;
                fill: #000;
            }
        }
    }

    &.has-name {
        &.is-size-small {
            a.sns {
                flex: none;
                height: auto;
                padding: 0.5em 0.8em;
            }
        }
    }

    &:not(.has-name) {
        a {
            flex: none;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            .sns_icon {
                width: 20px;
                height: 20px;
            }
        }
        &.is-size-small {
            a {
                width: 30px;
                height: 30px;
                .sns_icon {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &.color-type-text {
            .sns_icon {
                width: 26px;
                height: 26px;
            }
            &.is-size-small {
                .sns_icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    &.color-type-text {
        gap: 10px;
        a.sns {
            height: auto;
            width: auto;
            padding: 0;
            flex: none;
            border-radius: 0;
        }
    }

    // &.is-size-small{
    //     font-size:.7em;
    //     a{
    //         height: 30px;
    //         width: 30px;
    //     }
    // }
}
