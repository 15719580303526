#header {
    background-color: #fff;
    .header-wrap {
        box-shadow: $box-shadow;

        &.is-layout-block {
            .header-main {
                padding: 1.5em 0;
                &__inner {
                    flex-direction: column;
                    align-items: center;
                    gap: 1.2em;
                }
                .logo {
                    padding: 0;
                }
                #nav > ul {
                    gap: 1.5em;
                    & > li > a {
                        padding: 0;
                    }
                }
            }
        }
    }
}

.has-header-widget{
    .header-main__inner {
        align-items: center;
    }
}

/* logo -----------------------------------------*/

.logo {
    font-weight: 100;
    line-height: 1;
    margin-right: auto;
    display: flex;
    flex: 1;
    align-items: center;
    font-size: initial;
    margin-bottom: 0;
    padding: 1em 0;
    a {
        display: inline-block;
    }
    img {
        height: 55px;
        width: auto;
        vertical-align: middle;
        object-fit: contain;
    }
    .logo_title {
        font-size: 2em;
        font-weight: bold;
    }
    @include mq-down(lg) {
        justify-content: center;
    }
}

/* header main -----------------------------------------*/

.header-main {
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .header-nav {
        ul {
            .menu_desc {
                display: block;
            }
            li.menu-item {
                a {
                    text-decoration: none;
                    padding: 1.5em 0.8em;
                }
                ul.sub-menu {
                    a {
                        text-decoration: none;
                        padding: 0.8em;
                    }
                }
            }
        }
        @include mq-down(lg) {
            display: none;
        }
    }
    // #nav{
    //     font-size: .9em;
    //     z-index: 999;
    //     ul{
    //         position: relative;
    //         a{
    //             display: inline-block;
    //             .menu_desc{
    //                 font-size: .8em;
    //                 margin-top: 5px;
    //                 opacity: 0.5;
    //                 padding: 0 .5em;
    //             }
    //         }
    //         ul{
    //             position: absolute;
    //             top: 100%;
    //             left: 0px;
    //             .menu_desc {
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}

//   .header-logo #nav ul li:hover,#onlynav ul li:hover,#nav_fixed #nav ul li:hover {
//     background-color: rgba(255,255,255,.3);
//   }

//   #onlynav ul li a{
//     padding: 1em 1.3em;
//   }
//   #nav_fixed #nav ul li a{
//     padding: 1.4em .8em;
//   }

//   .header-logo #nav ul li:last-child ul,
//   #onlynav ul li:last-child ul,#nav_fixed #nav ul li:last-child ul {
//     right:0
//   }

//   .header-logo #nav ul ul li:not(:first-child),#onlynav ul ul li:not(:first-child),#nav_fixed #nav ul ul li:not(:first-child) {
//     border-top: none;
//   }
//   .header-logo #nav ul ul ul,#onlynav ul ul ul,#nav_fixed #nav ul ul ul {
//     position: absolute;
//     top: 0px;
//     left: 100%;
//   }

//   @media screen and (max-width:959px){
//         #nav,#onlynav{
//           display:none !important;
//       }
//   }

/* header mini -----------------------------------------*/

.header-mini {
    font-size: 0.8em;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        ul.menu {
            display: flex;
            height: 100%;
        }

        #description {
            padding: 5px;
        }
        @include mq-down(md) {
            justify-content: center;
            .header_small_menu_right {
                display: none;
            }
        }
    }
}

/* header fixed -----------------------------------------*/

body.admin-bar {
    #header-fix.is-fixed {
        top: $admin-bar-height-lg;
        @media screen and (max-width: 782px) {
            top: $admin-bar-height-md;
        }
        @media screen and (max-width: 600px) {
            top: 0;
        }
    }
    .single-article-container__side--inner{
        top: 72px + $admin-bar-height-lg;
        @media screen and (max-width: 782px) {
            top: 72px + $admin-bar-height-md;
        }
    }
    #fix_sidebar {
        top: 72px + $admin-bar-height-lg;
        @media screen and (max-width: 782px) {
            top: 72px + $admin-bar-height-md;
        }
    }

}
#header-fix {
    display: block;
    z-index: 1000;
    font-size: 0.9em;
    position: fixed;
    transition: 0.4s;
    top: -100px;
    width: 100%;
    left: 0;
    box-shadow: 0 0 15px -5px rgb(0 0 0 / 20%);
    &.is-fixed {
        top: 0;
        display: block;
    }

    .header-nav {
        .menu_desc {
            display: block;
        }

        ul {
            .menu_desc {
                display: none;
            }
        }
    }
    .logo {
        img {
            height: 30px;
        }
        .logo_title {
            font-size: 1.5em;
        }
    }
}

/* header btn -----------------------------------------*/

.header-btn {
    font-weight: bold;
    display: flex;
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 100px;
        padding: 15px 12px 14px;
        grid-gap: 6px;
        border-left:1px solid rgba(180,180,180,.2);
        i {
            font-size: 1.8em;
        }
        &:hover {
            opacity: 0.7;
        }
        &:last-child {
            border-right: 1px solid rgba(180,180,180,.2);
        }

        &--title {
            font-size: 0.6em;
            line-height: 1;
            font-weight: bold;
        }
    }
    @include mq-down(lg){
        display: none;
    }
    &.is-sp{
        display: none;
        border: none;
        font-weight: normal;
        font-size: .8em;
        @include mq-down(lg){
            display: flex;
        }
        .header-btn__item{
            min-width: auto;
            flex: 1;
            padding: 16px 8px;
            grid-gap: 8px;
        }
    }
}

/* widget -----------------------------------------*/

@include mq-down(lg){
    .nav_inleft{
        display: none;
    }
}

/* scrollnav -----------------------------------------*/

#scrollnav {
    position: relative;
    width: 100%;
    background: #fff;
    overflow-x: auto;
    display: block;
    box-shadow: 0 5px 5px rgb(0 0 0 / 4%) inset;
    * {
        white-space: nowrap;
        min-width: auto;
    }
    ul.menu {
        margin: 0 auto;
        list-style: none;
        flex-wrap: nowrap;
        text-align: center;
        li.menu-item {
            flex: 1 0 auto;
            a {
                font-size: 0.8em;
                padding: 8px;
                letter-spacing: normal;
                display: block;
                white-space: nowrap;
                font-weight: bold;
            }
            ul {
                display: none;
            }
        }
    }
    @include mq-up(md) {
        display: none;
    }
}

/* onlynav -----------------------------------------*/

#onlynav {
    background: #fff;
    border-top:1px solid rgb(180 180 180 / 20%);
    ul.menu {
        justify-content: center;
        .menu_desc {
            display: block;
        }
    }
    @include mq-down(md) {
        display: none;
    }
}

/* message -----------------------------------------*/

.header-message {
    background: #eee;
    text-align: center;
    font-weight: bold;
    position: relative;
    font-size: 0.9em;
    width: 100%;
    &__inner {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__badge {
        padding: 0.4em 0.6em;
        margin-right: 8px;
        background: #f00;
        vertical-align: middle;
        white-space: nowrap;
    }
    &__text {
        vertical-align: middle;
        text-align: left;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        @include mq-down(md){
            flex:1;
            span{
                animation : headerMessageScroll 15s linear infinite;
                white-space: nowrap;
                display: inline-block;
                padding-left: 100%;
            }
        }
    }
    &:hover {
        opacity: 0.7;
    }
    @include mq-down(md){
        font-size: .8em;
    }
}

@keyframes headerMessageScroll{
    0% { transform: translateX(0)}
    100% { transform: translateX(-100%)}
}

/* side btn -----------------------------------------*/

.header-side-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    &__title {
        font-size: 0.6em;
        padding-top: 0.3em;
        line-height: 1;
        display: none;
    }

    &:before {
        content: "";
        font-family: "fontAwesome";
        font-size: 1.5em;
    }
    &.drawer-btn {
        &:before {
            content: "\f0c9";
        }
    }
    &.header-search {
        &:before {
            content: "\f002";
        }
    }
    @include mq-up(lg) {
        display: none;
    }
}

/* header-nav -----------------------------------------*/

ul.header-nav {
    height: 100%;
    li.menu-item {
        a{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        ul.sub-menu {
            position: absolute;
            min-width: 100%;
            z-index: 9;
            margin: 0;
            top: 100%;
            right: 0;
            text-align: left;
            visibility: hidden;
            opacity: 0;
            background-color: inherit;
            font-size: 1.3rem;
            box-shadow: 0 8px 8px rgb(0 0 0 / 10%);
            font-size: 1.3rem;
            background-color: #fff;
            visibility: hidden;
            opacity: 0;
            transition: .2s ease-in-out;
            .menu_desc {
                display: none;
            }
            ul.sub-menu{
                right: 100%;
                top: 0;
                box-shadow: -4px 0px 4px rgb(0 0 0 / 10%);
            }
            li.menu-item a{
                align-items: flex-start;
                padding: 0.8em 1.4em;
            }
        }
        &:hover {
            & > a{
                color: #000;
                background-color: #eee;
                opacity: .5;
            }

            & > ul.sub-menu {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

/* drawer -----------------------------------------*/

.drawer-nav {
    background: #fff;
    color:#000;
    top: 0;
    left: -100%;
    z-index: 99999;
    -webkit-overflow-scrolling: touch;
    width: 80%;
    height: 100%;
    max-width: 300px;
    transition: 0.35s ease-in-out;
    overflow-y: scroll;
    position: fixed;
    visibility: hidden;
    &.active {
        left: 0px;
        visibility: visible;
    }

    .drawer-widget-title {
        padding: 10px;
        background: #333;
        color: #fff;
    }
}

body.admin-bar {
    .drawer-nav {
        top: $admin-bar-height-lg;
        @media screen and (max-width: 782px) {
            top: $admin-bar-height-md;
        }
    }
}

.drawer-overlay {
    transition: 0.4s;
    position: fixed;
    &.active {
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 9999;
    }
}

// .footermenu_col .drawer-nav-btn {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     left: 0;
//     top:0;
//     transform: translateY(0%);
// }

// .drawer_content_title {
//     padding: 10px;
//     margin-bottom: 10px;
//     background: #eee;
// }

// .drawer-nav .diver_widget_adarea{
//   padding: 10px;
// }

// .drawer-nav .widget > ul:first-of-type,
// .drawer-nav .widget ul:first-child{
//   margin:0;
//   margin-top:-10px;
// }

// .footermenu_col .drawer-nav-btn span,
// .footermenu_col .drawer-nav-btn:before,
// .footermenu_col .drawer-nav-btn:after{
//   background:none;
//   border:none;
// }

/* search popup -----------------------------------------*/

#header_search {
    background-color: #fff;
    overflow: auto;
    margin: 0 auto;
    border-radius: 5px;
    padding: 1em;
    .diver_widget_adarea {
        padding: 10px;
    }
}

.searchbox_content_title {
    padding: 10px;
    background: #eee;
}
