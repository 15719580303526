@for $i from 2 through 9 {
    $column_size: (calc(969px / $i) - (20 - $i));
    $column_size_mini: (calc(699px / $i) - (10 - $i));

    $grid-gap: 20px - $i;

    @include mq-up(sm) {
        // .grid-md-#{$i} {
        //     grid-template-columns: repeat($i, minmax(0px, 1fr));
        // }

        .column-max-#{$i} {
            grid-template-columns: repeat(auto-fill, minmax($column_size, 1fr));

            &.is-size-mini {
                grid-template-columns: repeat(auto-fill, minmax($column_size_mini, 1fr));
            }

            &.is-column-fix {
                grid-template-columns: repeat($i, 1fr);
            }
        }
    }

}

@for $i from 1 through 4 {
    @include mq-down(sm) {
        .column-min-#{$i} {
            grid-template-columns: repeat($i, 1fr);
        }
    }
}


.row{
    display: flex;
    flex-wrap: wrap;
    @include mq-down(md){
        &.sp{
            flex-direction: column;
            div[class^="col"],
            div[class^="sc_col"]{
                width: 100%;
                float: none;
            }
        }
    }
    gap: 1em;
    &.padding0{
        gap: 0;
    }
    div[class^="col"],
    div[class^="sc_col"]{
        flex: auto;
        & > *:first-child {
            margin-top: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    &.is-align-center{
        align-items:center
    }
    &.is-align-end{
        align-items:flex-end
    }
}

@for $i from 2 through 4 {

            // width: math.div(100%,$i);
    .col#{$i},
    .sc_col#{$i} {
        width: math.div(100%,$i);
    }

    .col#{$i}_#{$i - 1},
    .sc_col#{$i}_#{$i - 1} {
        width: 100% - math.div(100,$i);
    }
}

/* column -----------------------------------------*/

.diver-column {
    display: grid;
    &.has-gap {
        grid-gap: 1em;
    }
    &__col {
        & > *:first-child {
            margin-top: 0;
        }
        & > *:last-child {
            margin-bottom: 0;
        }
    }
    &.is-align-center{
        align-items:center
    }
    &.is-align-end{
        align-items:flex-end
    }
}
