body {
    &.has-footer-btn {
        @include mq-down(md) {
            margin-bottom: 60px;
        }
        @include mq-down(sm) {
            margin-bottom: 80px;
        }
    }
}

.l-width-wide {
    width: 90%;
    margin: 0 auto;
}

#main-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px 20px;
    width: 90%;
    max-width: 100%;
    margin: 0 auto;
    @include mq-down(lg) {
        flex-direction: column;
    }
    @include mq-down(md) {
        width: 96%;
        gap: 10px;
    }
    .main-title {
        padding: 10px 20px;
        font-size: 1.1em;
        margin: 0;
        position: relative;
        font-weight: bold;
    }
}

.container-has-bg {
    background-color: #fff;
    .single_title {
        padding: 14px 16px;
    }
    .wrap-post-box {
        padding: 16px;
    }
}

.containerwidget {
    width: 100%;
}

.hentry,
.container-has-bg {
    background-color: #fff;
}

/****************************************

          main

*****************************************/

main#main {
    flex: 1;
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    width: 100%;
    .widget {
        .main-title {
            margin-bottom: 12px;
        }
    }
}

// #main,
// #sidebar{
//     border-bottom: 1px solid transparent;
// }

article.page {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    .page-title{
        margin-top: -14px;
    }
}

/****************************************

          sidebar

*****************************************/

#sidebar {
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    width: 330px;
    @include mq-down(lg) {
        width: 100%;
    }
}

#fix_sidebar {
    position: sticky;
    top: 72px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}

body.l-sidebar-left {
    #main-wrap,
    #single-article-container {
        flex-direction: row-reverse;
    }
}

/****************************************

          single

*****************************************/

.l-post-article {
    padding: 28px;
    @include mq-down(md) {
        padding: 18px 14px;
        overflow: hidden;
    }

    @include mq-down(sm) {
        margin: 0px -2%;
    }
}

/****************************************

          page

*****************************************/

body.page-template-template-fullpage {
    grid-gap: 0;
    #main-wrap {
        width: 100%;
        overflow: hidden;
    }
}

/****************************************

          lp

*****************************************/

body.single-lp {
    gap: 0;
    margin: 0;
    #main-wrap {
        display: block;
        padding: 0;
    }
    .lp-firstview{
        display: block;
        img{
            width: 100%;
        }
    }
    #footer{
        margin: 0 auto;
        width: 100%;
        .footer_content{
            justify-content: center;
        }
    }
}

/****************************************

          Category

*****************************************/

.category-header {
    width: 100%;
    background-color: #000;
    color: #fff;
    position: relative;
    &.has-bg-img{
        background-position: center;
        background-size: cover;
    }
    .cover{
        background-size: 100% auto;
        background-position: center center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #000;
        opacity: .5;
    }

    &:not(.has-tag){
        .category-header__inner {
            justify-content: center;
            .category-header-title{
                text-align: center;
            }
        }
    }
    
    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        grid-gap: 20px;
        padding: 36px 42px;
        position: relative;
        @include mq-down(lg){
            flex-direction: column;
            padding: 18px 24px;
        }
    }
    &__meta {
        width: 62%;
        .category-header-description {
            margin-top: 1em;
        }
        @include mq-down(lg){
            width: 100%;
        }
    }
    &-title {
        margin: 0;
    }

    &__tags {
        flex: 1;
        @include mq-down(lg){
            display: none;
        }
    }
}

// .category-header-wrap{
//   color: #333;
//     padding: 15px 0;
//     border-bottom: 1px solid #eee;
// }

// .category-header {
//     margin: auto;
//     width: 570px;
// }

// .category-title{
//   display:inline-block;
//   vertical-align: middle;
//   text-align: center;
// }

// .category-title-name{
//   font-size: 35px;
// }

// .category-title-horline{
//   margin: 0 0 10px 0;
//   width:50px;
//   height:5px;
//   display:inline-block;
//   vertical-align: middle;
//   border-radius: 10px;
// }

// .category-title-line{
//   margin:0 20px;
//   width:5px;
//   height:60px;
//   background:#fff;
//   display:inline-block;
//   vertical-align: middle;
//   border-radius: 10px;
// }
// .category-title-desc {
//     width: 300px;
//     overflow: auto;
//   display:inline-block;
//   vertical-align: middle;
//   font-size: 15px;
// }

// .catpage_content_wrap {
//     margin-bottom: 10px;
//     margin-top:-10px;
//     position: relative;
//     background: transparent !important;
//     overflow: hidden;
// }

// .catpage_content_wrap .cover{
//   background-size: 100% auto;
//   background-position: center center;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top:0;
//   left: 0;
//   z-index: -1;
//   filter: blur(2px) brightness(.8);
// }

// .catpage_description{
//   font-size: 1.1em;
//   margin-top: 10px;
// }

// .catpage_content_wrap .share{
//   padding:0;
// }

// .catpage_content_wrap  .innner {
//     padding: 1em 0;
//     width: 90%;
//     margin: 0 auto;
// }

// .catpage_content_wrap .catpage_inner_content {
//     width: 60%;
//     float: left;
//     padding: 1em;
//     font-weight: bold;
//     text-shadow: 1px 1px 0 #999;
// }

// .catpage_content_wrap .content {
//     padding: 1em 40px;
//     line-height: 1.5;
// }

// h1.catpage_title {
//     margin: 0;
// }

// .catpage_tag {
//     width: 40%;
//     float: left;
//     padding: 1em;
// }

// .catpage_tag a {
//     background: #eee;
//     color: #000;
//     white-space: nowrap;
//     border: 1px solid #ddd;
//     padding: 5px 10px;
//     margin: 3px 1px;
//     display: inline-block;
//     border-radius: 2px;
// }

// a.tag-cloud-link:before {
//     content: "\f02b";
//     margin-right: 5px;
//     color: #7e9da7;
//     font-family: fontAwesome;
// }

// .cat-post-main .content{
//   margin: 0;
// }

// .cat-post-main{
//   padding:1em 2em;
//   margin-bottom: 10px;
// }

// @media screen and (max-width:768px){
//   .catpage_content_wrap .cover{
//     background-size: auto 100%;
//   }
//   .catpage_content_wrap .catpage_inner_content {
//     width: 100%;
//   }

//   .catpage_tag {
//     display: none;
//   }

//   .catpage_content_wrap{
//     margin-top: -5px;
//   }

//   .cat-post-main{
//     padding: 1em;
//   }
// }

/****************************************

          404

*****************************************/

body.error404 {
    #main-wrap {
        max-width: 768px;
        #main {
            gap: 1.8em;
            padding: 100px 0;
        }
    }
    .diver-404-head {
        text-align: center;
        &__title {
            font-weight: 900;
            font-size: 10em;
            line-height: 1;
            font-family: system-ui;
        }
        &__text {
            font-size: 2em;
        }
    }
    .diver-404-message {
        text-align: center;
    }
}

/****************************************

          footer

*****************************************/

#footer {
    margin-top: auto;
    margin-bottom: 0;
}
