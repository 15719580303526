.comment-form{
    position: relative;
    font-size: .9em;
    padding: 25px;
    background-color: #f9f9f9;
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-end;
    #cancel-comment-reply-link {
        padding: .5em;
        margin: 10px 10px 0 0;
        opacity: .6;
    }
    &__head {
        display: flex;
        width: 100%;
        align-items:center;
        margin-bottom: 10px;
        position: relative;
        &--right{
            flex:1;
        }
    }
    &__label{
        font-size: .8em;
    }
    &__input{
        .c-form-control{
            margin-bottom: 10px;
        }
        input[type=text]{
            padding: 10px;
            width: 100%;
            border: none;
        }
    }

    &__icon {
        padding-right: 20px;
        text-align: center;
        img {
            width: 85px;
            height: 85px;
            background: #bcd2ff;
            border-radius: 50%;
            margin-bottom: 10px;
        }
    }
    // &-overlay{
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(255,255,255,.6);
    //     z-index: 999;
    //     &-loader{
    //         color: #b3d5ff;
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translateY(-50%) translateX(-50%);
    //     }
    //     }
    }

    #commentform{
    h3#reply-title {
        margin-bottom: 0;
        margin-top: 15px;
        border-bottom: 2px solid #000;
        padding: 5px;
    }


    #comment{
        display: block;
        width: 100%;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 10px;
        border: none;
        min-width: 100%;
    }
    .form-submit{
        text-align: right;
        margin: 0;
        &-messsage{
        font-size: 12px;
        text-align: left;
        margin-bottom: 10px;
        color: #f00;
        }
        input#submit {
        margin: 10px auto 0;
        font-size: 14px;
        font-weight: bold;
        background: #000;
        color: #fff;
        border-radius: 25px;
        }
    }
}
    

.comment-meta{
    display: flex;
}
.comment-list{
    padding: 0;
    margin: 0;
    &-notfound{
      color: #ccc;
      margin: 50px 0 70px;
    }
  
    &__sort{
      display: flex;
      font-size: .8em;
      margin: 10px 0;
      a,span{
        padding: 0 10px;
        display: inline-block;
      }
      a{
        color: #2196f3;
      }
      li + li{
        border-left: 1px solid #ccc;
      }
    }
  
    .comment-body {
      position: relative;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .comment-author {
        margin-right: 10px;
        .comment-author-name {
          font-weight: bold;
          margin-right: 5px;
          font-size: 14px;
        }
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .comment-meta{
      }
      .comment-info {
          flex: 1;
          font-size: .9em;
          &__head{
            align-items:center;
            font-size: .9em;
            & > *{
              margin-right: 6px;
            }
          }
          &-name{
            color: #555;
            &.is-author{
              background: #666;
              color: #fff;
              font-size: .9em;
              padding: .1em 5px .2em;
              border-radius: 5px;
            }
          }
          &-time {
            color: #777;
            letter-spacing: .1em;
        }
        .comment-content {
            margin: 5px 0;
        }
        .reply-target {
            color: #6eb2ff;
            margin-right: 5px;
            &:before{
              content:"＠";
            }
        }
      }
      .reply {
          a{
          display: inline-block;
          }
      }
      &.unapproved{
        color: #999;
        border-radius: 5px;
        background: #eaf8ff;
      }
    }
    ul.children {
      margin-left: 65px;
      ul{
        margin-left: 0;
      }
  
      .comment-body{
        margin-bottom: 0;
        .comment-author img{
          width: 35px;
          height: 35px;
        }
        .comment-meta {
            margin-top: 10px;
        }
      }
      #respond{
        margin-left: 0;
      }
    }
  
    #respond{
      margin-left: 65px;
    }
  }

.comments-pagination{
    margin: 1em 0;
    .page-numbers{
        display: inline-block;
        background: #fff;
        color: #666;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        border-radius: 3px;
        padding: 0;
        width: 32px;
        line-height: 32px;
        text-align: center;
        &.current{
            background: #eee;
            color: #aaa;
            box-shadow: none;
        }
    }
}