@import "../setting";

.sc_balloon {
    color: #333;
    position: relative;
    display: inline-block;
    padding: 1em 1.2em;
    background: #fff;
    border: 2px solid #ccc;
    z-index: 0;
    border-radius: 5px;
    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-color: transparent;
    }
    &::before {
        border-width: 8px 11px;
    }
    &::after {
        border-width: 10px;
        border-width: 5px 8px;
    }

    .custom_voice,
    .custom_voice2 {
        display: inline-block;
        position: absolute;
        border-width: 5px 8px;
        z-index: 1;

    }

    &.bottom {
        margin-bottom: 9px;
        &:before {
            top: 100%;
            left: 10%;
            border-top-color: inherit;
            border-width: 11px 8px;
        }
        &:after {
            top: 100%;
            left: calc(10% + 3px);
            border-top-color: #fff;
            border-width: 8px 5px;
        }
    }
    &.top {
        margin-top: 9px;
        &:before {
            bottom: 100%;
            left: 10%;
            border-bottom-color: inherit;
            border-width: 11px 8px;
        }
        &:after {
            bottom: 100%;
            left: calc(10% + 3px);
            border-bottom-color: #fff;
            border-width: 8px 5px;
        }
    }
    &.left {
        margin-left: 9px;
        &:before {
            right: 100%;
            top: 10%;
            border-right-color: inherit;
        }
        &:after {
            right: 100%;
            top: calc(10% + 3px);
            border-right-color: #fff;
        }

        .custom_voice {
            right: 100%;
            top: calc(10% + 3px);
            border-right-color: #fff;
            border-style: solid;
        }
    }
    &.right {
        margin-right: 9px;
        &:before {
            left: 100%;
            top: 10%;
            border-left-color: inherit;
        }
        &:after {
            left: 100%;
            top: calc(10% + 3px);
            border-left-color: #fff;
        }

        .custom_voice {
            left: 100%;
            top: calc(10% + 3px);
            border-left-color: #fff;
            border-style: solid;
        }
    }

    @each $key, $color in $auxiliary-color {
        &.#{$key} {
            // color: map-get($color, "text");
            border: none;
            border-color: rgba(map-get($color, "bg"), 0.2);
            background-color: rgba(map-get($color, "bg"), 0.2);
            &:after {
                content: none;
            }
            &.color-type-border{
                border: 2px solid map-get($color, "bg");
                background-color: #fff;
                &:after {
                    content: "";
                }
            }
        }
    }
    &.white {
        border: 1px solid #ccc;
    }
}

.think_balloon {
    position: relative;
    padding: 0.5em 1em;
    border-radius: 10px;
    font-size: 0.9em;
    margin: 12px;
    border: 2px solid #ddd;
    &:before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        top: -13px;
        border-radius: 50%;
        border: 2px solid #ccc;
    }
    &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: -5px;
        border-radius: 50%;
        border: 2px solid #ccc;
    }
    .custom_voice {
        &.left {
            left: -26px;
            width: 10px;
            height: 10px;
            top: -13px;
            border-radius: 50%;
            border: 1px solid #ccc;
        }
        &.right {
            right: -25px;
            width: 10px;
            height: 10px;
            top: -13px;
            border-radius: 50%;
            border: 1px solid #ccc;
        }
    }
    .custom_voice2 {
        &.left {
            left: -16px;
            width: 14px;
            height: 14px;
            top: -5px;
            border-radius: 50%;
            z-index: 11;
            border: 1px solid #ccc;
        }
        &.right {
            right: -15px;
            width: 14px;
            height: 14px;
            top: -5px;
            border-radius: 50%;
            z-index: 11;
            border: 1px solid #ccc;
        }
    }

    @each $key, $color in $auxiliary-color {
        &.#{$key} {
            // color: map-get($color, "text");
            border: none;
            background-color: rgba(map-get($color, "bg"), 0.2);
            &:before,
            &:after {
                border: none;
                background-color: rgba(map-get($color, "bg"), 0.2);
            }
        }
    }
}
