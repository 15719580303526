@import "../setting";

.diver_review_table {
    font-size: 0.9em;
    margin: 0 auto;
    max-width: 100%;
    overflow: auto;
    th {
        width: 20%;
        white-space: nowrap;
        text-align: center;
    }
    td {
        width: 80%;
        &.table_harf {
            width: 30%;
        }
    }
    .review_star {
        background-size: 100px;
    }
    @include mq-down(md) {
        table {
            border-bottom: none;
        }
        tr {
            display: flex;
            flex-wrap: wrap;
            th {
                width: 30%;
                border: 0;
                border-bottom: 1px solid #ccc;
            }
            td {
                width: 70%;
                border: 0;
                border-bottom: 1px solid #ccc;
                border-left: 1px solid #ccc;
                &.table_harf {
                    width: 70%;
                }
            }
        }
    }
}
